export const TOGGLE_WORKSPACE_MODAL = 'TOGGLE_WORKSPACE_MODAL'
export const UPDATE_WORKSPACE_FORM = 'UPDATE_WORKSPACE_FORM'

export const CREATE_WORKSPACE = {
  REQUEST: 'CREATE_WORKSPACE',
  PENDING: 'CREATE_WORKSPACE_PENDING',
  FULFILLED: 'CREATE_WORKSPACE_FULFILLED',
  FAILED: 'CREATE_WORKSPACE_FAILED'
}

export const GET_WORKSPACES = {
  REQUEST: 'GET_WORKSPACES',
  PENDING: 'GET_WORKSPACES_PENDING',
  FULFILLED: 'GET_WORKSPACES_FULFILLED',
  FAILED: 'GET_WORKSPACES_FAILED'
}

export const SET_WORKSPACE = {
  REQUEST: 'SET_WORKSPACE',
  PENDING: 'SET_WORKSPACE_PENDING',
  FULFILLED: 'SET_WORKSPACE_FULFILLED',
  FAILED: 'SET_WORKSPACE_FAILED'
}