import React from 'react'
import ReactTooltip from 'react-tooltip'

const Icon = ({ icon, text, color, muted, onClick = null, tooltipDirection = 'left' }) => 
  <>
    <ReactTooltip place={tooltipDirection} effect='solid' className='bold'/>
    <span 
        data-tip={text} 
        className={`pointer material-icons ${muted ? 'muted' : ''} ${color}`}
        style={{ color: color}}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick()
          }
        }}>
      {icon}
    </span>
  </>

export default Icon