export const GET_DISTRIBUTOR = {
  REQUEST: "GET_DISTRIBUTOR",
  PENDING: "GET_DISTRIBUTOR_PENDING",
  FULFILLED: "GET_DISTRIBUTOR_FULFILLED",
  FAILED: "GET_DISTRIBUTOR_REJECTED"
}

export const INVITE_DISTRIBUTOR_USER = {
  REQUEST: "INVITE_DISTRIBUTOR_USER",
  PENDING: "INVITE_DISTRIBUTOR_USER_PENDING",
  FULFILLED: "INVITE_DISTRIBUTOR_USER_FULFILLED",
  FAILED: "INVITE_DISTRIBUTOR_USER_REJECTED"
}

export const REMOVE_DISTRIBUTOR_USER = {
  REQUEST: "REMOVE_DISTRIBUTOR_USER",
  PENDING: "REMOVE_DISTRIBUTOR_USER_PENDING",
  FULFILLED: "REMOVE_DISTRIBUTOR_USER_FULFILLED",
  FAILED: "REMOVE_DISTRIBUTOR_USER_REJECTED"
}

export const UPDATE_DISTRIBUTOR_INVITE = 'UPDATE_DISTRIBUTOR_INVITE'