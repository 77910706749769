import {
  GET_FILES,
  SET_SEARCH,
  SET_CHECKED_FILE,
  GET_SUBTITLE_FILES,
  MATCH_FILE,
  SET_SELECTED_SUBTITLES,
  SET_BURNED_IN_LANGUAGES
} from './types'

const initialState = {
  status: 'PENDING',
  list: [],
  search: '',
  breadcrumb: [],
  checkedFile: {},
  subtitleFiles: [],
  selectedSubtitles: {},
  burnedInLanguages: {},
  matching: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      }
    case GET_FILES.PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: []
      }
    case GET_FILES.FULFILLED:
      return {
        ...state,
        status: state.search === action.payload.data.search ? 'COMPLETE' : state.status,
        list: state.search === action.payload.data.search ? action.payload.data.files : state.list,
        breadcrumb: state.search === action.payload.data.search ? action.payload.data.breadcrumb : state.breadcrumb
      }
    case GET_FILES.FAILED:
      return {
        ...state,
        status: 'FAILED',
        list: []
      }
    case GET_SUBTITLE_FILES.FULFILLED:
      return {
        ...state,
        subtitleFiles: action.payload.data.subtitleFiles
      }
    case SET_CHECKED_FILE: 
       return {
        ...state,
        checkedFile: action.payload.file
      }
    case SET_SELECTED_SUBTITLES: 
       return {
        ...state,
        selectedSubtitles: action.payload.selectedSubtitles
      }
    case SET_BURNED_IN_LANGUAGES: 
       return {
        ...state,
        burnedInLanguages: action.payload.burnedInLanguages
      }
    case MATCH_FILE.PENDING:
      return {
        ...state,
        matching: true
      }
    case MATCH_FILE.FAILED:
      return {
        ...state,
        matching: false
      }
    case MATCH_FILE.FULFILLED:
      return {
        ...state,
        list: state.list.map(file => action.payload.data.files[file.id] ? action.payload.data.files[file.id] : file),
        checkedFile: {}
      }
  default:
    return state
  }
}