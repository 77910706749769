import React from 'react'

const Breadcrumb = ({ breadcrumb, onClick }) =>
  <div className='flex flex-auto'>
    <button className='cs-button link mr1' style={{ fontSize: '1.571em', 'fontWeight': 'bold' }} onClick={() => onClick(null)}>Files</button>
    {breadcrumb.map((item, i) => 
      <span className='flex items-center' key={i} style={{ marginTop: '1px' }}>
        {i < breadcrumb.length && <span className='material-icons small'>chevron_right</span>} <button className='cs-button link mx1' onClick={() => onClick(item)}>{item.name}</button>
      </span>
    )}
  </div>

export default Breadcrumb