import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducer'
import auth from './auth/reducer'
import manifests from './manifests/reducer'
import inputs from './inputs/reducer'
import outputs from './outputs/reducer'
import files from './files/reducer'
import uploads from './uploads/reducer'
import downloads from './downloads/reducer'
import distributors from './distributors/reducer'
import team_members from './team/reducer'
import invite from './invite/reducer' 
import profile from './profile/reducer'
import security from './security/reducer'
import distributor from './distributor/reducer'
import workspace from './workspace/reducer'
import transcode_jobs from './transcode-jobs/reducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  auth,
  manifests,
  inputs,
  outputs,
  files,
  uploads,
  downloads,
  distributors,
  team_members,
  invite,
  profile,
  security,
  distributor,
  workspace,
  transcode_jobs
})

export default createRootReducer
