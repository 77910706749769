import React from 'react'
import { Table } from '@bitcine/cs-theme'
import TableData from '../../components/TableData'

const StreamsTable = ({ streams }) =>
  <Table 
    widths={['auto', 140, 140, 140, 200]}
    header={{
      columns: [
        { },
        { },
        { },
        { },
        { }
      ]
    }}
    body={{
      data: streams,
      row : {
        compact: true,
        render: [
          data => <TableData
            text={data.body.codec_long_name}
            subtext={data.body.codec_name}/>,
          data => data.type,
          data => data.body.avg_frame_rate,
          data => data.body.tags ? data.body.tags.language : '',
          data => data.body.tags ? data.body.tags.handler_name : ''
        ]
      }
    }}/>

export default StreamsTable