import React from 'react'
import { Modal, Table } from "@bitcine/cs-theme"

const StreamsModal = ({ file, close }) =>
  <Modal
    open={true}
    width={3}
    title={file.name}
    onClose={() => close()}>
    <Table
      widths={['auto', 100, 100, 100]}
      header={{
        columns: [
          {},
          {},
          {},
          {}
        ]
      }}
      body={{
        data: file.streams,
        row : {
          compact: true,
          render: [
            data => data.body.codec_long_name,
            data => data.body.codec_name,
            data => data.type,
            data => data.body.avg_frame_rate
          ]
        },
        empty: {
          icon: 'waves',
          title: 'No streams!',
          text: 'This media file does not have any streams...'
        }
      }}/>
  </Modal>

export default StreamsModal