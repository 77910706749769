import queryString from 'query-string'

import {
  GET_OUTPUTS,
  SET_SEARCH
} from '../../redux/outputs/types'

import { get } from '../fetch'

export const getOutputs = (search, skip, take, sort) => dispatch => dispatch(
  get(GET_OUTPUTS.REQUEST, `outputs?${queryString.stringify({search, skip, take, sortBy: sort.key, sortDirection: sort.direction})}`)
)

export const setSearch = search => dispatch => dispatch({
  type: SET_SEARCH,
  payload: {
    search
  }
})