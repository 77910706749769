import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { ButtonDropdown } from '@bitcine/cs-theme'
import AC_Logo from './ac_logo.svg'
import styled from '@emotion/styled'
import MemberIcon from '../MemberIcon'
import { logout } from '../../api/auth'
import { toggleWorkspaceModal } from '../../api/workspace'
import CreateWorkspacesModal from '../Workspace/Modals/Create'
import WorkspaceDropdown from '../Workspace/Dropdown'

const HeaderNav = styled.div `
  position: fixed;
  height: 60px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 0 9px 0 rgba(0,0,0,.13);
`

const SelectableInput = styled.button`
  border-left: 1px solid #c7cdcf;
  border-right: 1px solid #c7cdcf;
  border-top: none;
  border-bottom: none;
  outline: none;
  text-align: left;
  padding: 0 5px 0 15px;
  cursor: pointer;
  height: 60px;

  small,span {
    display: block;
  }

  small {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1px;
  }

  span {
    font-size: 1.2em;
  }

  .icon {
    margin-right: 8px;
  }

  &:hover {
    background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);
  }
`

const LogoContainer = styled.div`
  width: 219px;
  height: 60px;
`

class HeaderBar extends React.Component {
  render() {
    const { isAuthed, logout, user, push, toggleWorkspaceModal, workspace } = this.props
    return (
      <HeaderNav className='pr2 flex items-center z1'>
        <>
          <div className='flex items-center flex-auto'>
            <LogoContainer className='flex items-center'>
              <Link to='/'>
                <div className='flex items-center pl2'>
                  <img width='160px' src={AC_Logo} alt='Air Canada Logo'/>
                </div>
              </Link>
            </LogoContainer>
            {isAuthed &&
              <>
                <div className='relative col-3'>
                  <div className='flex items-center'>
                    <SelectableInput onClick={() => toggleWorkspaceModal('list')}>
                      <div className='flex items-center'>
                        <div className='icon'>
                          <i className='material-icons'>library_books</i>
                        </div>
                        <div>
                          <small>Workspace</small>
                          <span>{user.details.organization.workspace.name}</span>
                        </div>
                        <div className='icon ml2'>
                          <i className='material-icons'>keyboard_arrow_down</i>
                        </div>
                      </div>
                    </SelectableInput>
                  </div>
                </div>
              </>
            }
          </div>
          {isAuthed &&
              <ButtonDropdown
                button={{
                  className: 'cs-button link',
                  text: (
                    <div className='flex items-center'>
                      <MemberIcon name={user.details.name} />
                      <div className='right-align mr1'>
                        <div>{user.details.name}</div>
                        <div className='small block'>{user.details.organization.name}</div>
                      </div>
                      <span className='material-icons'>keyboard_arrow_down</span>
                    </div>
                  )
                }}
                dropdown={{
                  clickCloses: true,
                  content: [
                    {
                      text: 'Dashboard',
                      icon: 'dashboard',
                      onClick: () => push('/'),
                    },
                    {
                      text: 'Account',
                      icon: 'person',
                      onClick: () => push('/account'),
                    },
                    {
                      text: 'Team',
                      icon: 'people',
                      onClick: () => push('/team'),
                      breakAfter: true
                    },
                    {
                      text: 'Sign Out',
                      icon: 'exit_to_app',
                      onClick: logout,
                    }
                  ]
                }}
              />
          }

          {workspace.modals.create && <CreateWorkspacesModal />}
          {workspace.modals.list && <WorkspaceDropdown />}
        </>
      </HeaderNav> 
    )
  }
}

const mapStateToProps = state => ({
  isAuthed: state.user.isAuthed,
  user: state.user,
  workspace: state.workspace
})

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
  push: bindActionCreators(push, dispatch),
  toggleWorkspaceModal: bindActionCreators(toggleWorkspaceModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar)