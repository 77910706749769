import React from 'react'
import { connect } from 'react-redux'
import UserCard from './components/UserCard'
import Skeleton from './components/Skeleton'

class TeamMemberList extends React.Component {
  render() {
    const { isLoading, members } = this.props
    return isLoading ? <Skeleton /> : (
      <div className='clearfix mxn2'>
        {members.map(member =>
          <div key={member.id} className='col col-3 p2'>
            <UserCard user={member} />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  members: state.team_members.list.map(member => {
    return {
      ...member,
      _key: member.id
    }
  }),
  isLoading: state.team_members.status === 'PENDING',
  hasFailed: state.team_members.status === 'FAILED',
})

export default connect(mapStateToProps)(TeamMemberList)