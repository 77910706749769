import React, { useState } from 'react'
import { connect } from 'react-redux'
import Inputs from '../../components/Inputs'
import Files from '../Files'
import { Button } from '@bitcine/cs-theme'
import MatchingModal from '../../components/MatchingModal'

const Matching = ({ matching, checkedInput, checkedFile }) => {
  const [showMatchingModal, toggleMatchingModal] = useState(false)
  return (
    <div>
      <div className='flex items-center justify-between'>
        <h3>Match Files</h3>
        <Button 
          white={true}
          disabled={!checkedInput.id || !checkedFile.id || matching}
          onClick={() => toggleMatchingModal(true)}>
          Match File to Input
        </Button>
      </div>
      <div className='flex mt2 border border-gray-5 rounded p2'>
        <div className='col-6 pr1'>
          <Inputs minimal={true}/>
        </div>
        <div className='col-6 pl1'>
          <Files/>
        </div>
      </div>
      { showMatchingModal && <MatchingModal close={() => toggleMatchingModal(false)} />}
    </div>
  )
}

const mapStateToProps = state => ({
  checkedInput: state.inputs.checkedInput,
  checkedFile: state.files.checkedFile,
  matching: state.files.matching
})

export default connect(mapStateToProps)(Matching)