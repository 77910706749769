import { toast } from "react-toastify"

import {
  MATCH_FILE,
  SET_BURNED_IN_LANGUAGES,
  SET_SELECTED_SUBTITLES,
} from '../../redux/files/types'

import { put } from '../fetch'

export const setSelectedSubtitles = selectedSubtitles => dispatch => dispatch({
  type: SET_SELECTED_SUBTITLES,
  payload: {
    selectedSubtitles
  }
})

export const confirmBurnedInLanguages = burnedInLanguages => dispatch => dispatch({
  type: SET_BURNED_IN_LANGUAGES,
  payload: {
    burnedInLanguages
  }
})

export const matchInput = () => (dispatch, getState) => {
  const inputID = getState().inputs.checkedInput.id
  const fileID = getState().files.checkedFile.id
  const selectedSubtitles = getState().files.selectedSubtitles

  dispatch(
    put(
      MATCH_FILE.REQUEST, 
      `match/${inputID}`, 
      { 
        fileID,
        selectedSubtitles
      }, 
      res => {
        if(res.success) {
          toast.info("Matched file to input!")
        }
      }
    )
  )
}

export const unmatchInput = inputID => dispatch => {
  dispatch(
    put(MATCH_FILE.REQUEST, `unmatch/${inputID}`, {}, res => {
      if(res.success) {
        toast.info("Detached file from input!")
      }
    })
  )
}