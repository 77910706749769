import {
  GET_INVITE_PENDING,
  GET_INVITE_FULFILLED,
  GET_INVITE_REJECTED,
  UPDATE_INVITE_PENDING,
  UPDATE_INVITE_FULFILLED,
  UPDATE_INVITE_REJECTED,
  UPDATE_INVITE_FORM
} from './types'

const initialState = {
  status: 'PENDING',
  user: {
    email: '',
    requires_setup: false
  },
  sender: {
    name: ''
  },
  organization: {
    name: ''
  },
  form: {
    name: '',
    password: '',
    isSubmitting: false,
    error: ''
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_INVITE_PENDING: 
      return {
        ...state,
        ...initialState
      }
    case GET_INVITE_FULFILLED: 
      return {
        ...state,
        status: 'READY',
        user: action.payload.data.user,
        organization: action.payload.data.organization,
        sender: action.payload.data.sender,
      }
    case GET_INVITE_REJECTED:
      return {
        ...state,
        ...initialState,
        status: 'FAILED'
      }
    case UPDATE_INVITE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.key]: action.payload.value
        }
      }
    case UPDATE_INVITE_PENDING:
      return {
        ...state,
        form: {
          ...state.form,
          isSubmitting: true,
          error: ''
        }
      }
    case UPDATE_INVITE_FULFILLED:
      return {
        ...state
      }
    case UPDATE_INVITE_REJECTED:
      return {
        ...state,
        form: {
          ...state.form,
          isSubmitting: false,
          password: '',
          error: action.payload.message
        }
      }
  default:
    return state
  }
}