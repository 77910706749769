import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updatePassword, changePasswordField } from '../../api/security'

class ChangePassword extends React.Component {
  render() {
    const { security, updatePassword, changePasswordField } = this.props
    return (
      <>
        <h4 className='mb1'>Security</h4>
        <label htmlFor='cpass' className='cs-label'>Current Password</label>
        <input 
          id='cpass'
          className='cs-input col-12 mb2'
          type='password'
          placeholder='Enter your current password'
          disabled={security.password.isSubmitting}
          value={security.password.current}
          onChange={(e) => changePasswordField('current', e.target.value)} />
        <label htmlFor='npass' className='cs-label'>New Password</label>
        <input 
          id='npass'
          className='cs-input col-12 mb2'
          type='password'
          placeholder='New password...'
          disabled={security.password.isSubmitting}
          value={security.password.new}
          onChange={(e) => changePasswordField('new', e.target.value)} />
        <label htmlFor='copass' className='cs-label'>Confirm Password</label>
        <input 
          id='copass'
          className='cs-input col-12 mb3'
          type='password'
          placeholder='Confirm password...'
          disabled={security.password.isSubmitting}
          value={security.password.confirmation}
          onChange={(e) => changePasswordField('confirmation', e.target.value)} />
        <button className='cs-button' disabled={security.password.isSubmitting} onClick={() => {
          updatePassword()
        }}>Save</button>
        {security.password.error.length > 0 && (
          <div className='pt2 red'>
            {security.password.error}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security
})

const mapDispatchToProps = dispatch => ({
  updatePassword: bindActionCreators(updatePassword, dispatch),
  changePasswordField: bindActionCreators(changePasswordField, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)