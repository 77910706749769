export const TOGGLE_TRANSCODE_JOB_MODAL = 'TOGGLE_TRANSCODE_JOB_MODAL'

export const GET_TRANSCODE_JOB = {
  REQUEST: "GET_TRANSCODE_JOB",
  PENDING: "GET_TRANSCODE_JOB_PENDING",
  FULFILLED: "GET_TRANSCODE_JOB_FULFILLED",
  FAILED: "GET_TRANSCODE_JOB_FAILED"
}

export const TRIGGER_TRANSCODE_JOB = {
  REQUEST: "TRIGGER_TRANSCODE_JOB",
  PENDING: "TRIGGER_TRANSCODE_JOB_PENDING",
  FULFILLED: "TRIGGER_TRANSCODE_JOB_FULFILLED",
  FAILED: "TRIGGER_TRANSCODE_JOB_FAILED"
}