import React from 'react'
import styled from '@emotion/styled'
import ChangePassword from '../../components/ChangePassword'
import UpdateProfile from '../../components/UpdateProfile'

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 9px 0 rgba(0,0,0,.13);
`

class Account extends React.Component {
  render() {
    return (
      <div className='mx-auto max-width-4 pt2'>
        <h3 className='mb2'>Account Management</h3>
        <div className='flex flex-wrap content-start mxn2'>
          <div className='col-6 p2'>
            <Card className='p2'>
              <UpdateProfile />
            </Card>
          </div>
          <div className='col-6 p2'>
            <Card className='p2'>
              <ChangePassword />
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default Account