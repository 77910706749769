import React from 'react'
import { connect } from 'react-redux'
import { createManifest } from '../../api/manifests'
import { bindActionCreators } from 'redux'

const UploadButton = ({ isUploading, createManifest }) =>
  <>
   <button 
      className='cs-button white small'
      disabled={isUploading}
      onClick={() =>{
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.csv, .xlsx, .xls'
        input.onchange = e => {
          createManifest(e.target.files[0])
        }
        document.body.append(input)
        input.click()
      }}>
      Upload Manifest XLS
    </button>
  </>

const mapStateToProps = state => ({
  isUploading: state.manifests.uploading
})

const mapDispatchToProps = dispatch => ({
  createManifest: bindActionCreators(createManifest, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadButton)