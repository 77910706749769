import {
  TOGGLE_TRANSCODE_JOB_MODAL,
  GET_TRANSCODE_JOB,
  TRIGGER_TRANSCODE_JOB
} from './types'

const initialState = {
  modal: {
    show: false,
    isNew: false,
    outputID: null
  },
  transcodeJob: {},
  status: 'PENDING'
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_TRANSCODE_JOB_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          show: !state.modal.show,
          output: action.payload.output
        }
      }
    case GET_TRANSCODE_JOB.PENDING:
      return {
        ...state,
        status: 'PENDING'
      }
    case GET_TRANSCODE_JOB.FULFILLED:
      return {
        ...state,
        status: 'COMPLETE',
        transcodeJob: action.payload.data.jobData
      }
    case GET_TRANSCODE_JOB.FAILED:
      return {
        ...state,
        status: 'FAILED'
      }
    case TRIGGER_TRANSCODE_JOB.PENDING:
      return {
        ...state,
        status: 'PENDING'
      }
    case TRIGGER_TRANSCODE_JOB.FAILED:
      return {
        ...state,
        status: 'FAILED'
      }
    case TRIGGER_TRANSCODE_JOB.FULFILLED:
      return {
        ...state,
        status: 'COMPLETE',
        jobData: {},
        modal: initialState.modal
      }
  default:
    return state
  }
}