import queryString from 'query-string'

import {
  GET_DOWNLOADS,
  SET_SEARCH
} from '../../redux/downloads/types'

import { get } from '../fetch'

export const getDownloads = (search, skip, take, sort) => dispatch => dispatch(
  get(GET_DOWNLOADS.REQUEST, `download-jobs?${queryString.stringify({search, skip, take, sortBy: sort.key, sortDirection: sort.direction})}`)
)

export const setSearch = search => dispatch => dispatch({
  type: SET_SEARCH,
  payload: {
    search
  }
})