import { toast } from "react-toastify"

import {
  TOGGLE_TRANSCODE_JOB_MODAL,
  TRIGGER_TRANSCODE_JOB,
  GET_TRANSCODE_JOB
} from '../../redux/transcode-jobs/types'

import { get, post } from '../fetch'

export const toggleTranscodeJobModal = output => dispatch => dispatch({
  type: TOGGLE_TRANSCODE_JOB_MODAL,
  payload: {
    output
  }
})

export const triggerTranscodeJob = outputID => dispatch =>
dispatch(
  post(
    TRIGGER_TRANSCODE_JOB.REQUEST, `outputs/${outputID}/trigger-transcode-job`, 
    {}, 
    res => {
      if (res.success) toast.info("Triggered transcode job!")
      else toast.error("Failed to trigger transcode job: " + res.message)
    },
    res => {
      toast.error(res.message)
    }
  )
)

export const getTranscodeJob = outputID => dispatch => dispatch(
  get(GET_TRANSCODE_JOB.REQUEST, `outputs/${outputID}/transcode-job`)
)