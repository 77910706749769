import {
  LOGIN,
  UPDATE_AUTH_FIELD,
  LOGOUT
} from '../../redux/auth/types'

import { post } from '../fetch'

export const login = () => (dispatch, getState) => dispatch(
  post(LOGIN.REQUEST, `auth`, {
    email: getState().auth.emailAttempt, 
    password: getState().auth.passwordAttempt
  })
)

export const updateField = (field, value) => dispatch => dispatch({
  type: UPDATE_AUTH_FIELD,
  payload: {
    field, value
  }
})

export const logout = () => dispatch => {
  localStorage.setItem('token', '')
  return dispatch({
    type: LOGOUT
  })
}