import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, Button } from '@bitcine/cs-theme'
import OutputsTable from './outputs_table'
import StreamsTable from './streams_table'
import SubtitlesTable from './subtitles_table'
import { matchInput } from '../../api/matching'

const MatchingModal = ({ input, file, close, matching, matchInput}) =>
  <Modal
    open
    width={3}
    title={`${input.title} ${input.episode ? ': ' + input.episode : ''}`}
    onClose={() => close()}>
    <div>
      <h4 className='mb2'>Outputs</h4>
      <OutputsTable outputs={input.outputs}/>
      <h4 className='my2'>File Streams</h4>
      <StreamsTable streams={file.streams}/>
      {input.required_subtitles.length > 0 && <>
        <h4 className='my2'>Subtitles</h4>
        <SubtitlesTable subtitles={input.required_subtitles}/>
      </>}
      <div className='right-align mt2'>
        <Button disabled={matching} icon='check_circle' onClick={() => {
          close()
          matchInput()
        }}>Match File</Button>
      </div>
    </div>
  </Modal>

const mapStateToProps = state => ({
  input: state.inputs.checkedInput,
  file: state.files.checkedFile,
  matching: state.files.matching
})

const mapDispatchToProps = dispatch => ({
  matchInput: bindActionCreators(matchInput, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MatchingModal)