import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getDistributors, updateCreate, setSearch } from '../../api/distributors'
import { Status, Table } from '@bitcine/cs-theme'
import { Link } from 'react-router-dom'
import CreateModal from './components/create_modal'

const Distributors = ({ list, status, total, search, setSearch, getDistributors, create, updateCreate }) => {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState({ key: 'updated_at', direction: 'desc' })
  const take = 15
  useEffect(() => {
    getDistributors(search, page * take, take, sort)
  }, [getDistributors, search, page, sort])
  return (
    <div>
      <div className='flex items-center justify-between mb1'>
        <h3>Distributors</h3>
        <div className='flex'>
          <input 
            className='cs-input' 
            value={search ?? ''} 
            placeholder='Search...' 
            onChange={(e) => setSearch(e.target.value)}/>
          <button className='cs-button ml1' onClick={() => updateCreate('showModal', true)}>Add Distributor</button>
        </div>
      </div>
      <Status
        pending={status === 'PENDING'}
        error={status === 'ERROR'}>
        <Table 
          widths={['auto', 140, 140, 140, 140]}
          header={{
            sorting: {
              key: sort.key,
              direction: sort.direction,
              onSortChange: sort => setSort(sort)
            },
            columns: [
              { text: 'Name' },
              { text: 'Inputs' },
              { text: 'Outputs' },
              { text: 'Uploads' },
              { text: 'Team Members' }
            ]
          }}
          body={{
            data: list,
            row : {
              compact: true,
              render: [
                data => <Link to={`/distributors/`+data.id}>{data.name}</Link>,
                data => data.inputs_count,
                data => data.outputs_count,
                data => data.distributor_uploads_count,
                data => data.users_count
              ]
            },
            empty: {
              icon: 'business',
              title: 'No distributors!',
              text: 'Once distributors have been created they will appear here.'
            }
          }}
          paginate={{
            currentPage: page,
            pageCount: Math.ceil(total / take),
            onPageChange: page => setPage(page)
          }}/>
      </Status>
      {create.showModal && <CreateModal onClose={() => updateCreate('showModal', false)}/>}
    </div>
  )
}

const mapStateToProps = state => ({
  list: state.distributors.list,
  status: state.distributors.status,
  total: state.distributors.total,
  search: state.distributors.search,
  create: state.distributors.create
})

const mapDispatchToProps = dispatch => ({
  getDistributors: bindActionCreators(getDistributors, dispatch),
  updateCreate: bindActionCreators(updateCreate, dispatch),
  setSearch: bindActionCreators(setSearch, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Distributors)