import {
  UPDATE_PASSWORD_PENDING,
  UPDATE_PASSWORD_FULFILLED,
  UPDATE_PASSWORD_REJECTED,
  CHANGE_PASSWORD_FIELD
} from './types'

const initialState = {
  password: {
    current: '',
    new: '',
    confirmation: '',
    isSubmitting: false,
    error: ''
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PASSWORD_PENDING:
      return {
        ...state,
        password: {
          ...state.password,
          isSubmitting: true,
          error: ''
        }
      }
    case UPDATE_PASSWORD_FULFILLED:
      return {
        ...state,
        password: {
          ...state.password,
          current: '',
          new: '',
          confirmation: '',
          isSubmitting: false,
          error: ''
        }
      }
    case UPDATE_PASSWORD_REJECTED:
      return {
        ...state,
        password: {
          ...state.password,
          isSubmitting: false,
          error: action.payload.message
        }
      }
    case CHANGE_PASSWORD_FIELD:
      return {
        ...state,
        password: {
          ...state.password,
          [action.payload.key]: action.payload.value
        }
      }
  default:
    return state
  }
}