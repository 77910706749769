import queryString from 'query-string'

import {
  GET_INPUTS,
  SET_SEARCH,
  SET_CHECKED_INPUT
} from '../../redux/inputs/types'

import { get } from '../fetch'

export const getInputs = (search, skip, take, sort) => dispatch => dispatch(
  get(GET_INPUTS.REQUEST, `inputs?${queryString.stringify({search, skip, take, sortBy: sort.key, sortDirection: sort.direction})}`)
)

export const setCheckedInput = input => dispatch => dispatch({
  type: SET_CHECKED_INPUT,
  payload: {
    input
  }
})

export const setSearch = search => dispatch => dispatch({
  type: SET_SEARCH,
  payload: {
    search
  }
})