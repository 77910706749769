import React from 'react'
import styled from '@emotion/styled'

const Subtext = styled.span`
  display: block;
  font-size: 0.8em;
  line-height: 0.85em;
  letter-spacing: 0.036em;
  color: #9E9DA3;
  margin-left: 2px;
`

const TableData = ({ text, subtext = null }) =>
  <div className='truncate'>
    {text}
    {subtext && <Subtext>{subtext}</Subtext>}
  </div>

export default TableData