import {
  GET_MANIFESTS,
  CREATE_MANIFEST,
  UPDATE_MANIFEST,
  DELETE_MANIFEST,
  PARSE_MANIFEST
} from '../../redux/manifests/types'

import { get, post, put, del } from '../fetch'
import { toast } from "react-toastify"

export const getManifests = () => dispatch => dispatch(
  get(GET_MANIFESTS.REQUEST, 'manifests')
)

export const updateManifest = (manifestID, data) => dispatch => dispatch(
  put(UPDATE_MANIFEST.REQUEST, `manifests/${manifestID}`, data)
)

export const deleteManifest = manifestID => dispatch => dispatch(
  del(DELETE_MANIFEST.REQUEST, `manifests/${manifestID}`)
)

export const parseManifest = manifestID => dispatch => dispatch(
  post(PARSE_MANIFEST.REQUEST, `manifests/${manifestID}/parse`)
)

export const createManifest = file => dispatch => {
  dispatch(
    post(
      CREATE_MANIFEST.REQUEST,
      'manifests',
      {
        filename: file.name
      }, 
      results => dispatch(uploadManifest(results.data.manifestID, file, results.data.signedUrl))
    )
  )
}

const uploadManifest = (manifestID, file, signedUrl) => dispatch => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedUrl);
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if(xhr.status === 200) {
          toast.info("Manifest uploaded!")
          dispatch(updateManifest(manifestID, { uploaded: true }))
        } else {
          toast.error("An error occurred during manifest upload.")
        }
      }
    };
    xhr.send(file);
  });
}