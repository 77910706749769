import {
  GET_TEAM_MEMBERS_PENDING,
  GET_TEAM_MEMBERS_FULFILLED,
  GET_TEAM_MEMBERS_REJECTED,
  UPDATE_TEAM_INVITE,
  INVITE_TEAM_MEMBER_REJECTED,
  INVITE_TEAM_MEMBER_PENDING,
  INVITE_TEAM_MEMBER_FULFILLED,
  REMOVE_TEAM_MEMBER_PENDING,
  REMOVE_TEAM_MEMBER_FULFILLED
} from './types'

const initialState = {
  status: 'PENDING',
  list: [],
  invite: {
    showModal: false,
    isSubmitting: false,
    email: '',
    error: ''
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TEAM_MEMBERS_PENDING: 
    case REMOVE_TEAM_MEMBER_PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: []
      }
    case GET_TEAM_MEMBERS_FULFILLED:
    case REMOVE_TEAM_MEMBER_FULFILLED:
      return {
        ...state,
        status: 'READY',
        list: action.payload.data.users
      }
    case GET_TEAM_MEMBERS_REJECTED:
      return {
        ...state,
        status: 'FAILED',
        list: []
      }
    case UPDATE_TEAM_INVITE:
      return {
        ...state,
        invite: {
          ...state.invite,
          [action.payload.key]: action.payload.value
        }
      }
    case INVITE_TEAM_MEMBER_PENDING:
      return {
        ...state,
        invite: {
          ...state.invite,
          error: '',
          isSubmitting: true
        }
      }
    case INVITE_TEAM_MEMBER_REJECTED:
      return {
        ...state,
        invite: {
          ...state.invite,
          error: action.payload.message,
          isSubmitting: false
        }
      }
    case INVITE_TEAM_MEMBER_FULFILLED:
      return {
        ...state,
        status: 'READY',
        list: action.payload.data.users,
        invite: {
          showModal: false,
          isSubmitting: false,
          email: '',
          error: ''
        }
      }
  default:
    return state
  }
}