import React from 'react'
import styled from '@emotion/styled'
import { Link, withRouter } from 'react-router-dom'

const NavBar = styled.div `
  position: fixed;
  height: 100%;
  width: 220px;
  margin-top: 60px;
  background-color: #2A2A2A;
`

const Icon = styled.span`
  font-size: 24px;
`

const SidebarItem = styled.div`
  :hover {
    font-weight: bold;
    opacity: 100%;

  }
  color: white;
  opacity: 50%;
  border-left: 4px solid #2A2A2A;
  &.active {
    opacity: 100%;
    background-color: #373839;
    border-left: 4px solid white;
  }
  width: 100%;
`

const links = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'dashboard'
  },
  {
    name: 'Expected Inputs',
    to: '/inputs',
    icon: 'move_to_inbox'
  },
  {
    name: 'Outputs',
    to: '/outputs',
    icon: 'outlined_flag'
  },
  {
    name: 'Files',
    to: '/files',
    icon: 'storage'
  },
  {
    name: 'Match Files',
    to: '/matching',
    icon: 'check_circle'
  },
  {
    name: 'Uploads',
    to: '/uploads',
    icon: 'cloud_upload',
    breakBefore: true
  },
  {
    name: 'Download Jobs',
    to: '/downloads',
    icon: 'cloud_download'
  },
  {
    name: 'Distributors',
    to: '/distributors',
    icon: 'movie'
  }
]

class SideBar extends React.Component {
  render() {
    return (
      <NavBar className='pt2'>
        {links.map(link =>
          <Link to={link.to} key={link.to}>
            {link.breakBefore && <div className='mx2 my1 border-top border-gray-3'/>}
            <SidebarItem className={`flex items-center px2 py1 ${this.props.location.pathname.includes(link.to) ? 'active' : ''}`}>
              <Icon className='material-icons mr1'>
                {link.icon}
              </Icon>
              {link.name}
            </SidebarItem>
          </Link>
        )}
      </NavBar>
    )
  }
}

export default withRouter(SideBar)