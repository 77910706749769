import {
  GET_INPUTS,
  SET_CHECKED_INPUT,
  SET_SEARCH
} from './types'

import {
  MATCH_FILE
} from '../files/types'

const initialState = {
  status: 'PENDING',
  list: [],
  total: 0,
  search: '',
  checkedInput: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      }
    case GET_INPUTS.PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: []
      }
    case GET_INPUTS.FULFILLED:
      return {
        ...state,
        status: state.search === action.payload.data.search ? 'COMPLETE' : state.status,
        list: state.search === action.payload.data.search ? action.payload.data.inputs : state.list,
        total: state.search === action.payload.data.search ? action.payload.data.total : state.total
      }
    case GET_INPUTS.FAILED:
      return {
        ...state,
        status: 'FAILED',
        list: []
      }
    case SET_CHECKED_INPUT:
      return {
        ...state,
        checkedInput: action.payload.input
      }
    case MATCH_FILE.FULFILLED:
      return {
        ...state,
        list: state.list.map(input => input.id === action.payload.data.input.id ? action.payload.data.input : input),
        checkedInput: {}
      }
  default:
    return state
  }
}