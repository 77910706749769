import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUploads, setSearch } from '../../api/uploads'
import { triggerDownload } from '../../api/aspera'
import { Status, Table, ButtonDropdown } from '@bitcine/cs-theme'
import TableData from '../../components/TableData'
import Icon from '../../components/Icon'
import humanFileSize from '../../helpers/human_file_size'

const Uploads = ({ list, status, total, search, setSearch, getUploads, triggerDownload }) => {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState({ key: 'updated_at', direction: 'desc' })
  const take = 15
  useEffect(() => {
    getUploads(search, page * take, take, sort)
  }, [getUploads, search, page, sort])
  return (
    <div>
      <div className='flex items-center justify-between mb1'>
        <h3>Uploads</h3>
        <input 
          className='cs-input' 
          value={search ?? ''} 
          placeholder='Search...' 
          onChange={(e) => setSearch(e.target.value)}/>
      </div>
      <Status
        pending={status === 'PENDING'}
        error={status === 'ERROR'}>
        <Table 
          widths={[45, 'auto', 120, 140, 140, 60]}
          header={{
            sorting: {
              key: sort.key,
              direction: sort.direction,
              onSortChange: sort => setSort(sort)
            },
            columns: [
              { text: '' },
              { text: 'Name' },
              { text: 'Size' },
              { text: 'User'},
              { text: 'Last Updated'},
              { text: '' }
            ]
          }}
          body={{
            data: list,
            row : {
              compact: true,
              render: [
                data => <Icon 
                  icon={data.status === 'uploaded' ? 'check_circle' : 'error'}
                  color={data.status === 'uploaded' ? 'green' : 'gray'}
                  text={data.status === 'uploaded' ? 'Uploaded!' : 'Uploading or stalled...'}
                  tooltipDirection='right'/>,
                data => <TableData
                  text={data.filename}/>,
                data => <TableData
                  text={humanFileSize(data.filesize)}
                  subtext={data.progress + '%'}/>,
                data => <TableData
                  text={data.organization.name}
                  subtext={data.user.name}/>,
                data => <div>{data.updated_at}</div>,
                data => 
                  <ButtonDropdown
                    button={{
                      menu: true,
                      className: 'link material-icons',
                      text: 'more_vert'
                    }}
                    dropdown={{
                      clickCloses: true,
                      content: [
                        {
                          text: 'Download',
                          icon: 'cloud_download',
                          onClick: () => triggerDownload(data)
                        }
                      ]
                    }}/>
              ]
            },
            empty: {
              icon: 'cloud_upload',
              title: 'No uploads!',
              text: 'Wait for distributors to upload content to get started.'
            }
          }}
          paginate={{
            currentPage: page,
            pageCount: Math.ceil(total / take),
            onPageChange: page => setPage(page)
          }}/>
      </Status>
    </div>
  )
}

const mapStateToProps = state => ({
  list: state.uploads.list,
  status: state.uploads.status,
  total: state.uploads.total,
  search: state.uploads.search
})

const mapDispatchToProps = dispatch => ({
  getUploads: bindActionCreators(getUploads, dispatch),
  triggerDownload: bindActionCreators(triggerDownload, dispatch),
  setSearch: bindActionCreators(setSearch, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Uploads)