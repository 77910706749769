import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Status, Table, ButtonDropdown } from '@bitcine/cs-theme'
import { getManifests, deleteManifest, parseManifest } from '../../api/manifests'
import UploadButton from '../../components/UploadButton'
import Datapoints from '../../img/datapoints.svg'

const Dashboard = ({ list, status, getManifests, deleteManifest, parseManifest }) => {
  useEffect(() => {
    getManifests()
  }, [getManifests])
  return (
    <div>
      <div className='flex items-center justify-between mb2'>
        <h3>Manifests</h3>
        <UploadButton />
      </div>
      <Status
        pending={status === 'PENDING'}
        error={status === 'ERROR'}>
        <Table 
          widths={['auto', 200, 200, 65]}
          header={{
            columns: [
              { text: 'Name'},
              { text: 'Status' },
              { text: 'Created' },
              { text: '' }
            ]
          }}
          body={{
            data: list,
            row : {
              compact: true,
              render: [
                data => data.filename,
                data => <span className='capitalize'>{data.status}</span>,
                data => data.created_at,
                data => <ButtonDropdown 
                  button={{
                    menu: true,
                    className: 'link material-icons',
                    text: 'more_vert',
                  }}
                  dropdown={{
                    clickCloses: true,
                    content: [
                      {
                        text: 'Parse Manifest',
                        icon: 'assignment',
                        onClick: () => parseManifest(data.id),
                        show: data.status === 'pending'
                      },
                      {
                        text: 'Delete Manifest',
                        icon: 'delete',
                        onClick: () => deleteManifest(data.id),
                        show: true
                      }
                    ].filter(opt => opt.show)
                  }}/>
              ]
            },
            empty: {
              icon: <img src={Datapoints} alt='No Manifests'/>,
              title: 'No manifests!',
              text: 'Upload a manifest to get started.'
            }
          }}
          />
      </Status>
    </div>
  )
}

const mapStateToProps = state => ({
  list: state.manifests.list,
  status: state.manifests.status
})

const mapDispatchToProps = dispatch => ({
  getManifests: bindActionCreators(getManifests, dispatch),
  parseManifest: bindActionCreators(parseManifest, dispatch),
  deleteManifest: bindActionCreators(deleteManifest, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)