import React from 'react'
import { connect } from 'react-redux'
import { login, updateField } from '../../api/auth'
import { bindActionCreators } from 'redux'
import { Form, Input, Button, Error } from '../../components/AuthForm/'
import AuthLayout from '../../components/AuthLayout'

const Login = ({ auth, login, updateField }) => 
  <AuthLayout header="Sign in" description="Please enter your details below">
    <Form onSubmit={(e) => {
      e.preventDefault()
    }}>
      <Input
        autoFocus
        type="text" 
        value={auth.emailAttempt} 
        onChange={e => updateField('emailAttempt', e.target.value )}
        placeholder="Enter your email..." 
        disabled={auth.isSubmitting} />
      <Input 
        type="password" 
        value={auth.passwordAttempt} 
        onChange={e => updateField('passwordAttempt', e.target.value )}
        placeholder="Enter your password..."
        disabled={auth.isSubmitting} />
      <Button onClick={login} disabled={auth.isSubmitting}>Log In</Button>
      {auth.hasFailed && (
        <Error className="mt2">Invalid Credentials</Error>
      )}
    </Form>
  </AuthLayout>

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  updateField: bindActionCreators(updateField, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)