import queryString from 'query-string'

import {
  GET_DISTRIBUTORS,
  UPDATE_CREATE_DISTRIBUTOR,
  CREATE_DISTRIBUTOR,
  SET_SEARCH
} from '../../redux/distributors/types'

import {
  GET_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR_INVITE,
  INVITE_DISTRIBUTOR_USER,
  REMOVE_DISTRIBUTOR_USER
} from '../../redux/distributor/types'

import { get, post, del } from '../fetch'

import { push } from 'connected-react-router'

export const getDistributors = (search, skip, take, sort) => dispatch => dispatch(
  get(GET_DISTRIBUTORS.REQUEST, `distributors?${queryString.stringify({search, skip, take, sortBy: sort.key, sortDirection: sort.direction})}`)
)

export const getDistributor = id => dispatch => dispatch(
  get(GET_DISTRIBUTOR.REQUEST, `distributors/`+id)
)

export const updateInvite = (key, value) => dispatch => dispatch({
  type: UPDATE_DISTRIBUTOR_INVITE,
  payload: { key, value }
})

export const inviteUser = (id) => (dispatch, getState) => dispatch(
  post(INVITE_DISTRIBUTOR_USER.REQUEST, `distributors/${id}/users`, {
    name: getState().distributor.invite.name,
    email: getState().distributor.invite.email
  })
)

export const removeUser = (id, userId) => dispatch => dispatch(
  del(REMOVE_DISTRIBUTOR_USER.REQUEST, `distributors/${id}/users/${userId}`)
)

export const updateCreate = (key, value) => dispatch => dispatch({
  type: UPDATE_CREATE_DISTRIBUTOR,
  payload: { key, value }
})

export const createDistributor = () => (dispatch, getState) => dispatch(
  post(CREATE_DISTRIBUTOR.REQUEST, `distributors`, {
    name: getState().distributors.create.name,
    email: getState().distributors.create.email,
    organizationName: getState().distributors.create.organizationName
  }, res => {
    dispatch(push(`/distributors/` + res.data.distributor.id))
  })
)

export const setSearch = search => dispatch => dispatch({
  type: SET_SEARCH,
  payload: {
    search
  }
})