import {
  TOGGLE_WORKSPACE_MODAL,
  UPDATE_WORKSPACE_FORM,
  CREATE_WORKSPACE,
  GET_WORKSPACES,
  SET_WORKSPACE
} from '../../redux/workspace/types'

import {
  getManifests
} from '../manifests'

import {
  getInputs
} from '../inputs'

import {
  getOutputs
} from '../outputs'

import { post, get } from '../fetch'

export const toggleWorkspaceModal = (type) => dispatch => 
  dispatch({
    type: TOGGLE_WORKSPACE_MODAL,
    payload: {
      type
    }
  })

export const updateWorkspaceForm = (key, value) => dispatch =>
  dispatch({
    type: UPDATE_WORKSPACE_FORM,
    payload: {
      key, value
    }
  })

export const getWorkspaces = () => dispatch => dispatch(
  get(GET_WORKSPACES.REQUEST, `workspaces`)
)

export const createWorkspace = () => (dispatch, getState) => dispatch(
  post(CREATE_WORKSPACE.REQUEST, `workspaces`, { 
    name: getState().workspace.form.name
  }, () => {
      dispatch(getManifests())
      dispatch(getInputs(null, 1, 15, { key: 'id', direction: 'asc' }))
      dispatch(getOutputs(null, 1, 15, { key: 'id', direction: 'asc' }))
  })
)

export const setActiveWorkspace = id => dispatch => dispatch(
  post(SET_WORKSPACE.REQUEST, `workspaces/${id}/activate`, {}, () => {
    dispatch(getManifests())
    dispatch(getInputs(null, 1, 15, { key: 'id', direction: 'asc' }))
    dispatch(getOutputs(null, 1, 15, { key: 'id', direction: 'asc' }))
  })
)
