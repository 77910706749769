import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux' 
import AuthLayout from '../../components/AuthLayout'
import { getInvite, updateInviteForm, updateInvite } from '../../api/invite'
import { Status } from '@bitcine/cs-theme'
import { Form, Input, Button, Error } from '../../components/AuthForm/'


export class Invitation extends Component {
  componentDidMount() {
    this.props.getInvite(this.props.match.params.token)
  }
  render() {
    const { invite, user, updateInviteForm, updateInvite } = this.props

    return (
      <Status pending={invite.status === 'PENDING'} error={invite.status === 'FAILED'}>
        {user.isAuthed ? (
          <>
            {user.details.email !== invite.user.email ? (
              <h3>
                Invite invalid.
              </h3>
            ) : (
              <div className="center pt3">
                <h3 className='mb2'>Click the button to join {invite.organization.name}</h3>
                <button className='cs-button' disabled={invite.form.isSubmitting} onClick={() => {
                  updateInvite(this.props.match.params.token)
                }}>Join {invite.organization.name}</button>
              </div>
            )}
          </>
        ) : (
          <AuthLayout header={"Join " + invite.organization.name} description={
            invite.user.requires_setup
              ? 'Fill in the fields below to complete your account setup and accept the invitation.'
              : 'Please login below to accept this invitation.'
          }>
            <Form onSubmit={(e) => {
              e.preventDefault()
            }}>
              <Input 
                value={invite.user.email}
                disabled={true} />
              {invite.user.requires_setup &&
                <Input 
                  value={invite.form.name}
                  type='text'
                  placeholder="Enter your name..."
                  onChange={e => updateInviteForm('name', e.target.value)}/>
              }
              <Input
                value={invite.form.password}
                type='password'
                placeholder={invite.user.requires_setup ? "Create a password..." : "Enter your password..."}
                onChange={e => updateInviteForm('password', e.target.value)}/>
              <Button onClick={() => updateInvite(this.props.match.params.token)} disabled={invite.form.isSubmitting}>
                {invite.user.requires_setup ? "Create account" : "Log in"} &amp; accept invite
              </Button>

              {invite.form.error.length > 0 && (
                <Error className="mt2">{invite.form.error}</Error>
              )}
            </Form>
          </AuthLayout>
        )}
      </Status>
    )
  }
}

const mapStateToProps = (state) => ({
  invite: state.invite,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getInvite: bindActionCreators(getInvite, dispatch),
  updateInviteForm: bindActionCreators(updateInviteForm, dispatch),
  updateInvite: bindActionCreators(updateInvite, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Invitation)
