import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getDownloads, setSearch } from '../../api/downloads'
import { Status, Table } from '@bitcine/cs-theme'
import Icon from '../../components/Icon'

const constants = {
  'pending': {
    icon: 'access_time',
    color: 'gray',
    message: 'Pending download...'
  },
  'downloading': {
    icon: 'reset',
    color: 'lightgreen',
    message: 'Downloading...'
  },
  'error': {
    icon: 'error_outline',
    color: 'red',
    message: 'An error occurred with this download...'
  },
  'completed': {
    icon: 'check_circle',
    color: 'green',
    message: 'Successfully downloaded!'
  }
}

const Downloads = ({ list, status, total, search, setSearch, getDownloads, triggerDownload }) => {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState({ key: 'updated_at', direction: 'desc' })
  const take = 15
  useEffect(() => {
    getDownloads(search, page * take, take, sort)
  }, [getDownloads, search, page, sort])
  return (
    <div>
      <div className='flex items-center justify-between mb1'>
        <h3>Download Jobs</h3>
        <input 
          className='cs-input' 
          value={search ?? ''} 
          placeholder='Search...' 
          onChange={(e) => setSearch(e.target.value)}/>
      </div>
      <Status
        pending={status === 'PENDING'}
        error={status === 'ERROR'}>
        <Table 
          widths={[45, 'auto', 200, 200]}
          header={{
            sorting: {
              key: sort.key,
              direction: sort.direction,
              onSortChange: sort => setSort(sort)
            },
            columns: [
              { text: '' },
              { text: 'Name' },
              { text: 'Message' },
              { text: 'Last Updated' }
            ]
          }}
          body={{
            data: list,
            row : {
              compact: true,
              render: [
                data => <Icon 
                  icon={constants[data.status].icon}
                  color={constants[data.status].color}
                  text={constants[data.status].message}
                  tooltipDirection={'right'}/>,
                data => data.name,
                data => data.message,
                data => data.updated_at
              ]
            },
            empty: {
              icon: 'cloud_upload',
              title: 'No downloads!',
              text: 'Wait for distributors to upload content to get started.'
            }
          }}
          paginate={{
            currentPage: page,
            pageCount: Math.ceil(total / take),
            onPageChange: page => setPage(page)
          }}/>
      </Status>
    </div>
  )
}

const mapStateToProps = state => ({
  list: state.downloads.list,
  status: state.downloads.status,
  total: state.downloads.total,
  search: state.downloads.search
})

const mapDispatchToProps = dispatch => ({
  getDownloads: bindActionCreators(getDownloads, dispatch),
  setSearch: bindActionCreators(setSearch, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Downloads)