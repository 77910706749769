import {
  UPDATE_PROFILE_PENDING,
  UPDATE_PROFILE_FULFILLED,
  UPDATE_PROFILE_REJECTED
} from './types'

const initialState = {
  isSubmitting: false,
  error: ''
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PROFILE_PENDING:
      return {
        ...state,
        isSubmitting: true,
        error: ''
      }
    case UPDATE_PROFILE_FULFILLED:
      return {
        ...state,
        isSubmitting: false,
        error: ''
      }
    case UPDATE_PROFILE_REJECTED:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload.message
      }
  default:
    return state
  }
}