import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Status, Modal } from '@bitcine/cs-theme'
import { updateInvite, inviteUser } from '../../../api/distributors'

class InviteModal extends React.Component {
  close = () => {
    this.props.onClose()
    this.props.updateInvite('email', '')
    this.props.updateInvite('name', '')
    this.props.updateInvite('generatedPassword', '')
    this.props.updateInvite('isSubmitting', false)
    this.props.updateInvite('success', false)
    this.props.updateInvite('error', '')
  }
  render() {
    const { invite, updateInvite, inviteUser, id } = this.props
    return (
      <Modal
        open
        width={1}
        title={`Invite a user`}
        onClose={this.close}>
        <Status pending={invite.isSubmitting} error={false}>
          <div>
            {!invite.success ? (
              <>
                <label className='cs-label' htmlFor='invname'>Name</label>
                <input 
                  value={invite.name}
                  onChange={e => updateInvite('name', e.target.value)}
                  type='text' 
                  id='invname' 
                  className='cs-input col-12 mb2' 
                  placeholder='Enter their name...' />
                <label className='cs-label' htmlFor='invemail'>Email</label>
                <input 
                  value={invite.email}
                  onChange={e => updateInvite('email', e.target.value)}
                  type='email' 
                  id='invemail' 
                  className='cs-input col-12 mb3'
                  placeholder='Enter their email...' />
                <div className='flex justify-between'>
                  <button className='cs-button white' onClick={this.close}>Cancel</button>
                  <button className='cs-button' onClick={() => inviteUser(id)}>Send</button>
                </div>
                {invite.error.length > 0 && (
                  <div className='center pt2 center red'>
                    {invite.error}
                  </div>
                )}
              </>
            ) : (
              <div>
                <h3 className='mb2'>User created!</h3>
                <div><strong>Name:</strong> {invite.name}</div>
                <div className='mb3'><strong>Email:</strong> {invite.email}</div>
                <label className='cs-label'>Generated Password</label>
                <input className='cs-input col-12' value={invite.generatedPassword} onChange={(e) => {
                  e.preventDefault()
                }}/>
                <small>You will not be able to see this password again once you close this window. Please make sure to copy it down.</small>
                <div className='flex justify-between mt3'>
                  <button className='cs-button white' onClick={this.close}>Close</button>
                </div>
              </div>
            )}
          </div>
        </Status>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateInvite: bindActionCreators(updateInvite, dispatch),
  inviteUser: bindActionCreators(inviteUser, dispatch)
})

const mapStateToProps = state => ({
  invite: state.distributor.invite
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteModal)