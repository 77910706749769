import React from 'react'
import { Table } from '@bitcine/cs-theme'
import TableData from '../../components/TableData'

const OutputsTable = ({ outputs }) =>
  <Table 
    widths={['auto', 200, 200]}
    header={{
      columns: [
        { text: 'Output' },
        { text: 'Languages' },
        { text: 'Subtitles' }
      ]
    }}
    body={{
      data: outputs,
      row : {
        compact: true,
        render: [
          data => <TableData
            text={data.filename}
            subtext={data.end_point_delivery_details}/>,
          data => <span>{data.required_languages.map((lang, i) => 
            <span key={i}>{i > 0 && lang ? ', ' : ''}{lang}</span>
          )}</span>,
          data => <span>{data.required_subtitles.map((sub, i) => 
            <span key={i}>{i > 0 && sub ? ', ' : ''}{sub}</span>
          )}</span>
        ]
      }
    }}/>

export default OutputsTable