import {
  GET_MANIFESTS,
  CREATE_MANIFEST,
  UPDATE_MANIFEST,
  PARSE_MANIFEST,
  DELETE_MANIFEST
} from './types'

const initialState = {
  status: 'PENDING',
  uploading: false,
  list: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MANIFESTS.PENDING:
    case DELETE_MANIFEST.PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: []
      }
    case GET_MANIFESTS.FULFILLED:
    case DELETE_MANIFEST.FULFILLED:
      return {
        ...state,
        status: 'COMPLETE',
        list: action.payload.data.manifests
      }
    case GET_MANIFESTS.FAILED:
      return {
        ...state,
        status: 'FAILED',
        list: []
      }
    case CREATE_MANIFEST.FULFILLED:
      return {
        ...state,
        uploading: true,
        list: action.payload.data.manifests
      }
    case UPDATE_MANIFEST.FULFILLED:
    case PARSE_MANIFEST.FULFILLED:
      return {
        ...state,
        status: 'COMPLETE',
        uploading: false,
        list: state.list.map(manifest => 
          manifest.id === action.payload.data.manifest.id ? action.payload.data.manifest : manifest
        )
      }
  default:
    return state
  }
}