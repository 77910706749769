import {
  GET_OUTPUTS,
  UPDATE_OUTPUT,
  SET_SEARCH
} from './types'
import {
  TRIGGER_TRANSCODE_JOB
} from '../transcode-jobs/types'

const initialState = {
  status: 'PENDING',
  list: [],
  total: 0,
  search: ''
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      }
    case GET_OUTPUTS.PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: []
      }
    case GET_OUTPUTS.FULFILLED:
      return {
        ...state,
        status: state.search === action.payload.data.search ? 'COMPLETE' : state.status,
        list: state.search === action.payload.data.search ? action.payload.data.outputs : state.list,
        total: state.search === action.payload.data.search ? action.payload.data.total : state.total
      }
    case GET_OUTPUTS.FAILED:
      return {
        ...state,
        status: 'FAILED',
        list: []
      }
    case UPDATE_OUTPUT.FULFILLED:
    case TRIGGER_TRANSCODE_JOB.FULFILLED:
      return {
        ...state,
        list: state.list.map(l => l.id === action.payload.data.output.id ? action.payload.data.output : l)
      }
  default:
    return state
  }
}