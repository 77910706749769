import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Status, Table, Modal, ButtonDropdown, Checkbox } from '@bitcine/cs-theme'
import { getFiles, setCheckedFile, setSearch } from '../../api/files'
import StreamsModal from '../../components/StreamsModal'
import Breadcrumb from '../../components/Breadcrumb'
import Icon from '../../components/Icon'
import humanFileSize from '../../helpers/human_file_size'
import ReactJson from 'react-json-view'
import TableData from '../../components/TableData'

const Files = ({ list, status, search, setSearch, breadcrumb, getFiles, checkedFile, setCheckedFile }) => {
  const [parentID, setParentID] = useState(null)
  const [sort, setSort] = useState({ key: 'name', direction: 'asc' })
  const [viewMetadataFile, setViewMetadataFile] = useState(null)
  const [viewStreamsFile, setViewStreamsFile] = useState(null)
  useEffect(() => {
    getFiles(search, parentID, sort)
  }, [getFiles, search, parentID, sort])
  return (
    <div>
      <div className='flex items-center justify-between mb1'>
        <Breadcrumb breadcrumb={breadcrumb} onClick={(data) => setParentID(data ? data.id : null)}/>
        <input 
          className='cs-input' 
          value={search ?? ''} 
          placeholder='Search...' 
          onChange={(e) => setSearch(e.target.value)}/>
      </div>
      <Status
        pending={status === 'PENDING'}
        error={status === 'ERROR'}>
        <Table 
          widths={[45, 'auto', 90, 55]}
          header={{
            sorting: {
              key: sort.key,
              direction: sort.direction,
              onSortChange: sort => setSort(sort)
            },
            columns: [
              { },
              { text: 'Name', key: 'name' },
              { text: 'Size', key: 'size' },
              { }
            ]
          }}
          body={{
            data: list,
            row : {
              compact: true,
              onClick: (event, data, index) => {
                if (data.type === 'directory' || data.type === 'root') {
                  setParentID(data.id)
                }
                else if (!data.input) {
                  setCheckedFile(checkedFile.id === data.id ? {} : data)
                }
              },
              render: [
                data => data.input ?
                  <Icon 
                    icon='movie' 
                    color={'green'}
                    text={`This file was matched with input: ${data.input.episode ? 
                      data.input.title + ' - ' + data.input.episode :
                      data.input.title
                    }`}
                    tooltipDirection='right'/>
                  : data.type === 'file' ? 
                  <Checkbox 
                    small 
                    checked={data.id === checkedFile.id}/> :
                  <span className='material-icons muted'>{data.type === 'file' ? 'movie' : 'folder'}</span>,
                data => <TableData
                  text={data.name}
                  subtext={data.subtitle_code ? data.subtitle_code + (data.is_closed_captions ? ' CC' : ' Subtitles') : null}/>,
                data => data.size ? humanFileSize(data.size) : '',
                data => data.type === 'file' &&
                  <ButtonDropdown 
                    button={{
                      menu: true,
                      className: 'link material-icons',
                      text: 'more_vert',
                    }}
                    dropdown={{
                      clickCloses: true,
                      content: [
                        {
                          text: 'Metadata',
                          icon: 'info',
                          onClick: () => setViewMetadataFile(data),
                          show: data.metadata && data.metadata.body
                        },
                        {
                          text: 'Streams',
                          icon: 'list',
                          onClick: () => setViewStreamsFile(data),
                          show: true
                        }
                      ].filter(opt => opt.show)
                    }}/>
              ]
            }
          }}
          />
      </Status>
      {
        viewMetadataFile && 
          <Modal open width={3} title={viewMetadataFile.name} onClose={() => setViewMetadataFile(null)}>
            <ReactJson 
              src={viewMetadataFile.metadata.body} 
              name='metadata' 
              enableClipboard={false} 
              displayObjectSize={false} 
              displayDataTypes={false} 
              style={{ fontFamily: 'inherit', fontSize: '12px', lineHeight: '12px' }}/>
          </Modal>
      }
      {
        viewStreamsFile &&
          <StreamsModal file={viewStreamsFile} close={() => setViewStreamsFile(null)}/>
      }
    </div>
  )
}


const mapStateToProps = ({ files }) => ({
  list: files.list.filter(file => !file.name.startsWith('.')),
  status: files.status,
  breadcrumb: files.breadcrumb,
  search: files.search,
  checkedFile: files.checkedFile
})

const mapDispatchToProps = dispatch => ({
  getFiles: bindActionCreators(getFiles, dispatch),
  setSearch: bindActionCreators(setSearch, dispatch),
  setCheckedFile: bindActionCreators(setCheckedFile, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Files)