import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal } from '@bitcine/cs-theme'
import { toggleWorkspaceModal, updateWorkspaceForm, createWorkspace }  from '../../../api/workspace'

export class CreateWorkspacesModal extends Component {
  cancel = () => {
    this.props.updateWorkspaceForm('name', '')
    this.props.toggleWorkspaceModal('create')
  }
  render() {
    const { updateWorkspaceForm, form, createWorkspace } = this.props
    return (
      <Modal
        title="Create Workspace"
        open={true}
        isDismissable={!form.isSubmitting}
        onClose={this.cancel}>
        <div>
          <label className='cs-label' htmlFor='ws_name'>Workspace name</label>
          <input 
            id='ws_name'
            value={form.name}
            onChange={e => updateWorkspaceForm('name', e.target.value)}
            className='cs-input col-12 mb3' 
            autoFocus 
            placeholder="Name of workspace..."/>
          <div className='flex justify-between'>
            <button className='cs-button white small' onClick={this.cancel} disabled={form.isSubmitting}>Cancel</button>
            <button className='cs-button small' disabled={form.isSubmitting} onClick={createWorkspace}>Create</button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  form: state.workspace.form
})

const mapDispatchToProps = (dispatch) => ({
  toggleWorkspaceModal: bindActionCreators(toggleWorkspaceModal, dispatch),
  updateWorkspaceForm: bindActionCreators(updateWorkspaceForm, dispatch),
  createWorkspace: bindActionCreators(createWorkspace, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkspacesModal)
