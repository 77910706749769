import React, { useEffect } from 'react'
import TeamMemberList from '../../components/TeamMemberList'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTeamMembers, updateTeamInvite } from '../../api/team'
import TeamInviteModal from '../../components/TeamInviteModal'

const Team = ({ getTeamMembers, updateTeamInvite }) => {
  useEffect(() => {
    getTeamMembers()
  }, [getTeamMembers])
  return (
    <>
      <TeamInviteModal />
      <div className="mx-auto py2 px3">
        <div className='flex items-center mb1'>
          <h3 className='flex-auto'>Team Members</h3>
          <div className='flex items-center'>
            <button className='cs-button' onClick={() => updateTeamInvite('showModal', true)}>Invite Team Member</button>
          </div>
        </div>
        <TeamMemberList />
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  getTeamMembers: bindActionCreators(getTeamMembers, dispatch),
  updateTeamInvite: bindActionCreators(updateTeamInvite, dispatch)
})

export default connect(null, mapDispatchToProps)(Team)
