import {
  SET_JWT_TOKEN
} from '../redux/auth/types'

import config from '../config'

const DEFAULTS = {
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
}

const POST_DEFAULTS = {
  method: "POST",
  ...DEFAULTS
}

const DELETE_DEFAULTS = {
  method: "DELETE",
  ...DEFAULTS
}

const PUT_DEFAULTS = {
  method: "PUT",
  ...DEFAULTS
}
// 
export const post = (type, route, payload, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(POST_DEFAULTS, payload, "post"), callback, errorCb))
export const get = (type, route, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(DEFAULTS, null, "get"), callback, errorCb))
export const put = (type, route, payload, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(PUT_DEFAULTS, payload, "put"), callback, errorCb))
export const del = (type, route, payload, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(DELETE_DEFAULTS, payload, "delete"), callback, errorCb))

const getPayload = (defaults, payload, type) => ({
  ...defaults,
  ...getBody(payload, type)
})

const getBody = (payload, type) => (type === "get" ? {} : { body: JSON.stringify(payload) })

const api = (type, route, payload, callback, errorCb) => (dispatch, getState) =>
  dispatch({
    type,
    payload: new Promise((resolve, reject) =>
      fetch(config.BASE_API + route, {
        ...payload,
        headers: {
          ...payload.headers,
          Authorization: 'Bearer ' + (getState().auth.jwtToken || localStorage.getItem('token'))
        }
      })
        .then(res => {
          const ok = res.ok
          const status = res.status
          res.json().then(json => {
            if (ok) {
              if (callback && typeof callback === "function") {
                callback(json)
              }
              resolve(json)
            }
            else {
              if (errorCb && typeof errorCb === "function") {
                errorCb(json)
              }
              reject({
                message: json.message,
                status
              })
            }
            if (parseInt(status) < 500 && typeof json.data.token === 'string' && json.data.token !== 'current') {
              localStorage.setItem('token', json.data.token)
              dispatch({ type: SET_JWT_TOKEN, payload: json.data.token })
            }
          })
        })
        .catch(error => {
          if (errorCb && typeof errorCb === "function") {
            errorCb(error)
          }
          reject(error)
        })
    )
  })
