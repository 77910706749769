import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Status, Table, Checkbox } from '@bitcine/cs-theme'
import TableData from '../../components/TableData'
import Icon from '../../components/Icon'
import { getInputs, setCheckedInput, setSearch } from '../../api/inputs'
import { unmatchInput } from '../../api/matching'
import Datapoints from '../../img/datapoints.svg'

const Inputs = ({ minimal, status, list, total, search, setSearch, getInputs, checkedInput, setCheckedInput, unmatchInput }) => {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState({ key: 'id', direction: 'asc' })
  const take = 15
  useEffect(() => {
    getInputs(search, page * take, take, sort)
  }, [getInputs, search, page, sort])
  return (
    <div>
      <div className='flex items-center justify-between mb1'>
        <h3>Inputs</h3>
        <input 
          className='cs-input' 
          value={search ?? ''} 
          placeholder='Search...' 
          onChange={(e) => setSearch(e.target.value)}/>
      </div>
      <Status
        pending={status === 'PENDING'}
        error={status === 'ERROR'}>
        <Table 
          widths={minimal ? [35, 'auto', 120] : [40, 160, 'auto', 140]}
          header={{
            sorting: {
              key: sort.key,
              direction: sort.direction,
              onSortChange: sort => setSort(sort)
            },
            columns: [
              { text: '' },
              { text: 'Distributor', hide: minimal },
              { text: 'Title', key: 'title' },
              { text: 'Lang' }
            ].filter(col => !col.hide)
          }}
          body={{
            data: list,
            row : {
              compact: true,
              onClick: (event, data) => {setCheckedInput(checkedInput.id === data.id ? {} : data)},
              render: [
                data => data.video_file ? 
                  <Icon 
                    icon='movie'
                    color='green'
                    text={`This input was matched with file: ${data.video_file.name} (click to detach)`}
                    tooltipDirection='right'
                    onClick={() => {
                      if (window.confirm("Would you like to detach the file from this input?")) {
                        unmatchInput(data.id)
                      }
                    }} /> : 
                  <Checkbox 
                    small 
                    checked={data.id === checkedInput.id}
                    onChange={e => e.preventDefault()}/>,
                data => !minimal && <TableData text={data.distributor_name}/>,
                data => <TableData
                  text={data.episode || data.title}
                  subtext={data.episode ? data.title : null}/>,
                data => <TableData
                 text={data.required_languages.map((lang, i) => <span key={i}>{i > 0 && lang ? ', ' : ''}{lang}</span>)}
                 subtext={
                  data.required_subtitles.length > 0 && <>
                    {data.required_subtitles.map((sub, i) => <span key={i}>{i > 0 && sub ? ', ' : ''}{sub}</span>)}
                  </>
                }/>,
              ].filter((opt, index) => !(index === 1 && minimal)) // To hide specific columns
            },
            empty: {
              icon: <img src={Datapoints} alt='No Manifests'/>,
              title: search ? 'No results found!' : 'No manifests!',
              text: search ? 'Please refine your search filters.' : 'Upload a manifest to get started.'
            }
          }}
          paginate={{
            currentPage: page,
            pageCount: Math.ceil(total / take),
            onPageChange: page => setPage(page)
          }}/>
      </Status>
    </div>
  )
}

const mapStateToProps = ({ inputs }) => ({
  list: inputs.list,
  status: inputs.status,
  total: inputs.total,
  search: inputs.search,
  checkedInput: inputs.checkedInput
})

const mapDispatchToProps = dispatch => ({
  getInputs: bindActionCreators(getInputs, dispatch),
  setCheckedInput: bindActionCreators(setCheckedInput, dispatch),
  unmatchInput: bindActionCreators(unmatchInput, dispatch),
  setSearch: bindActionCreators(setSearch, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Inputs)