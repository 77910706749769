export const GET_DISTRIBUTORS = {
  REQUEST: "GET_DISTRIBUTORS",
  PENDING: "GET_DISTRIBUTORS_PENDING",
  FULFILLED: "GET_DISTRIBUTORS_FULFILLED",
  FAILED: "GET_DISTRIBUTORS_FAILED"
}

export const UPDATE_CREATE_DISTRIBUTOR = 'UPDATE_CREATE_DISTRIBUTOR'

export const CREATE_DISTRIBUTOR = {
  REQUEST: "CREATE_DISTRIBUTOR",
  PENDING: "CREATE_DISTRIBUTOR_PENDING",
  FULFILLED: "CREATE_DISTRIBUTOR_FULFILLED",
  FAILED: "CREATE_DISTRIBUTOR_REJECTED"
}

export const SET_SEARCH = 'SET_DISTRIBUTORS_SEARCH'