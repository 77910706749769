export const GET_INVITE = 'GET_INVITE'
export const GET_INVITE_PENDING = 'GET_INVITE_PENDING'
export const GET_INVITE_FULFILLED = 'GET_INVITE_FULFILLED'
export const GET_INVITE_REJECTED = 'GET_INVITE_REJECTED'

export const UPDATE_INVITE = 'UPDATE_INVITE'
export const UPDATE_INVITE_PENDING = 'UPDATE_INVITE_PENDING'
export const UPDATE_INVITE_FULFILLED = 'UPDATE_INVITE_FULFILLED'
export const UPDATE_INVITE_REJECTED = 'UPDATE_INVITE_REJECTED'

export const UPDATE_INVITE_FORM = 'UPDATE_INVITE_FORM'