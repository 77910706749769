import React from 'react'
import { connect } from 'react-redux'
import { Modal } from "@bitcine/cs-theme"
import { updateTeamInvite, inviteTeamMember } from '../../api/team'
import { bindActionCreators } from 'redux'

class TeamInviteModal extends React.Component {
  render() {
    const { invite, updateTeamInvite, inviteTeamMember } = this.props
    return (
      <Modal
        open={invite.showModal}
        title='Invite Team Member'
        onClose={() => {
          updateTeamInvite('showModal', false)
          updateTeamInvite('email', '')
          updateTeamInvite('error', '')
        }}
        isDismissable={!invite.isSubmitting}>
          <div>
            <label htmlFor='email' className='cs-label'>Enter their email </label>
            <input 
              id='email' 
              type='email'
              autoFocus
              disabled={invite.isSubmitting}
              value={invite.email}
              onChange={e => updateTeamInvite('email', e.target.value)}
              className='cs-input col-12' 
              placeholder='Enter their email...' />
            <div className='red mb2'>
              {invite.error.length > 0 ? invite.error : ''}
            </div>
            <div className='flex'>
              <div className='flex-auto'>
                <button className='cs-button white small' disabled={invite.isSubmitting} onClick={() => {
                  updateTeamInvite('showModal', false)
                  updateTeamInvite('email', '')
                  updateTeamInvite('error', '')
                }}>Cancel</button>
              </div>
              <button className='cs-button small' disabled={!invite.email.length || invite.isSubmitting} onClick={inviteTeamMember}>
                {invite.isSubmitting ? 'Sending...' : 'Send'}
              </button>
            </div>
          </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  invite: state.team_members.invite
})

const mapDispatchToProps = dispatch => ({
  updateTeamInvite: bindActionCreators(updateTeamInvite, dispatch),
  inviteTeamMember: bindActionCreators(inviteTeamMember, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamInviteModal)