import {
  GET_INVITE,
  UPDATE_INVITE,
  UPDATE_INVITE_FORM
} from '../../redux/invite/types'

import { get, put } from '../fetch'

import { push } from 'connected-react-router'

export const getInvite = token => dispatch => dispatch(
  get(GET_INVITE, `invites/${token}`)
)

export const updateInvite = (token) => (dispatch, getState) => dispatch(
  put(UPDATE_INVITE, `invites/${token}`, {
    name: getState().invite.form.name,
    password: getState().invite.form.password
  }, () => {
    dispatch(push('/'))
  })
)

export const updateInviteForm = (key, value) => dispatch => dispatch({
  type: UPDATE_INVITE_FORM,
  payload: { key, value }
})