import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, Status } from "@bitcine/cs-theme"
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { getTranscodeJob, toggleTranscodeJobModal } from '../../api/transcode-jobs'

const TranscodeJobModal = ({ output, close, status, getTranscodeJob, transcodeJob, toggleTranscodeJobModal }) => {
  useEffect(() => {
    getTranscodeJob(output.id)
  }, [])
  return (
    <Modal
      open={true}
      width={3}
      title={output.title}
      onClose={() => toggleTranscodeJobModal()}>
      <Status
        pending={status === 'PENDING'}
        error={status === 'FAILED'}>
        <JSONInput
          placeholder={transcodeJob}
          viewOnly={true}
          confirmGood={false}
          height='400px'
          width='100%'/>
        />
      </Status>
    </Modal>
  )
}

const mapStateToProps = state => ({
  transcodeJob: state.transcode_jobs.transcodeJob,
  output: state.transcode_jobs.modal.output,
  status: state.transcode_jobs.status
})

const mapDispatchToProps = dispatch => ({
  getTranscodeJob: bindActionCreators(getTranscodeJob, dispatch),
  toggleTranscodeJobModal: bindActionCreators(toggleTranscodeJobModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TranscodeJobModal)