import queryString from 'query-string'

import {
  SET_CHECKED_FILE,
  GET_FILES,
  GET_SUBTITLE_FILES,
  SET_SEARCH
} from '../../redux/files/types'

import { get } from '../fetch'

export const setCheckedFile = file => dispatch => dispatch({
  type: SET_CHECKED_FILE,
  payload: {
    file
  }
})

export const getFiles = (search, parentID, sort) => dispatch => dispatch(
  get(GET_FILES.REQUEST, `files?${queryString.stringify({search, parentID, sortBy: sort.key, sortDirection: sort.direction})}`)
)

export const getSubtitleFiles = () => dispatch => dispatch(
  get(GET_SUBTITLE_FILES.REQUEST, 'subtitle-files')
)

export const setSearch = search => dispatch => dispatch({
  type: SET_SEARCH,
  payload: {
    search
  }
})