import {
  GET_UPLOADS,
  SET_SEARCH
} from './types'

const initialState = {
  status: 'PENDING',
  list: [],
  search : '',
  total: 0
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      }
    case GET_UPLOADS.PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: []
      }
    case GET_UPLOADS.FULFILLED:
      return {
        ...state,
        status: state.search === action.payload.data.search ? 'COMPLETE' : state.status,
        list: state.search === action.payload.data.search ? action.payload.data.uploads : state.list,
        total: state.search === action.payload.data.search ? action.payload.data.total : state.total
      }
    case GET_UPLOADS.FAILED:
      return {
        ...state,
        status: 'FAILED',
        list: []
      }
  default:
    return state
  }
}