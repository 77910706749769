export const GET_MANIFESTS = {
  REQUEST: 'GET_MANIFESTS',
  PENDING: 'GET_MANIFESTS_PENDING',
  FULFILLED: 'GET_MANIFESTS_FULFILLED',
  FAILED: 'GET_MANIFESTS_FAILED'
}

export const UPDATE_MANIFEST = {
  REQUEST: 'UPDATE_MANIFESTS',
  PENDING: 'UPDATE_MANIFESTS_PENDING',
  FULFILLED: 'UPDATE_MANIFESTS_FULFILLED',
  FAILED: 'UPDATE_MANIFESTS_FAILED'
}

export const CREATE_MANIFEST = {
  REQUEST: 'CREATE_MANIFEST',
  PENDING: 'CREATE_MANIFEST_PENDING',
  FULFILLED: 'CREATE_MANIFEST_FULFILLED',
  FAILED: 'CREATE_MANIFEST_FAILED'
}

export const DELETE_MANIFEST = {
  REQUEST: 'DELETE_MANIFEST',
  PENDING: 'DELETE_MANIFEST_PENDING',
  FULFILLED: 'DELETE_MANIFEST_FULFILLED',
  FAILED: 'DELETE_MANIFEST_FAILED'
}

export const PARSE_MANIFEST = {
  REQUEST: 'PARSE_MANIFEST',
  PENDING: 'PARSE_MANIFEST_PENDING',
  FULFILLED: 'PARSE_MANIFEST_FULFILLED',
  FAILED: 'PARSE_MANIFEST_FAILED'
}