import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Status, Table, ButtonDropdown } from '@bitcine/cs-theme'
import TableData from '../../components/TableData'
import { getOutputs, setSearch } from '../../api/outputs'
import { toggleTranscodeJobModal, triggerTranscodeJob } from '../../api/transcode-jobs'
import Icon from '../../components/Icon'
import Datapoints from '../../img/datapoints.svg'
import TranscodeJobModal from '../../components/TranscodeJobModal'

const iconProps = status => {
  switch (status) {
    case 'Unmatched': 
      return {
        icon: 'search',
        color: 'gray',
        message: 'Pending a storage file matched to the input...'
      }
    case 'Untriggered': 
      return {
        icon: 'error_outline',
        color: 'orange',
        message: 'Pending transcode job trigger...'
      }
    case 'QueuedForTranscoding':
      return {
        icon: 'schedule',
        color: 'lightgreen',
        message: 'Queued...'
      }
    case 'Queued': 
      return {
        icon: 'schedule',
        color: 'lightgreen',
        message: 'Queued...'
      }
    case 'ValidatingSource': 
      return {
        icon: 'loop',
        color: 'green',
        message: 'Validating source...'
      }
    case 'Transcoding': 
      return {
        icon: 'loop',
        color: 'green',
        message: 'Transcoding...'
      }
    case 'OutputError': 
      return {
        icon: 'error_outline',
        color: 'red',
        message: 'An error occurred with this transcode...'
      }
    case 'Error': 
      return {
        icon: 'error_outline',
        color: 'red',
        message: 'An error occurred with this transcode...'
      }
    case 'Done': 
      return {
        icon: 'check_circle',
        color: 'green',
        message: 'Successfully transcoded!'
      }
    default: 
      return {
        icon: 'info',
        color: 'gray',
        message: status
      }
  }
}

const Outputs = ({ status, list, total, search, setSearch, getOutputs, toggleTranscodeJobModal, showTranscodeJobModal, triggerTranscodeJob }) => {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState({ key: 'id', direction: 'asc' })
  const take = 15
  useEffect(() => {
    getOutputs(search, page * take, take, sort)
  }, [getOutputs, search, page, sort])
  return (
    <div>
      <div className='flex items-center justify-between mb2'>
        <h3>Outputs</h3>
        <input 
          className='cs-input' 
          value={search ?? ''} 
          placeholder='Search...' 
          onChange={(e) => setSearch(e.target.value)}/>
      </div>
      <Status
        pending={status === 'PENDING'}
        error={status === 'ERROR'}>
        <Table 
          widths={[45, 'auto', 500, 200, 65]}
          header={{
            sorting: {
              key: sort.key,
              direction: sort.direction,
              onSortChange: sort => setSort(sort)
            },
            columns: [
              { text: '' },
              { text: 'Title' },
              { text: 'Output' },
              { text: 'Lang' },
              { text: '' }
            ]
          }}
          body={{
            data: list,
            row : {
              compact: true,
              render: [
                data => <Icon 
                  icon={iconProps(data.transcode_job_status).icon}
                  color={iconProps(data.transcode_job_status).color}
                  text={data.transcode_job_message || iconProps(data.transcode_job_status).message}
                  tooltipDirection={'right'}/>,
                data => <TableData
                  text={data.episode || data.title}
                  subtext={data.episode ? data.title : null}/>,
                data => <TableData
                  text={data.filename}
                  subtext={data.end_point_delivery_details}/>,
                data => <TableData
                 text={data.required_languages.map((lang, i) => <span key={i}>{i > 0 && lang ? ', ' : ''}{lang}</span>)}
                 subtext={
                  data.required_subtitles.length > 0 && <>
                    {data.required_subtitles.map((sub, i) => <span key={i}>{i > 0 && sub ? ', ' : ''}{sub}</span>)}
                  </>
                }/>,
                data => <ButtonDropdown 
                  button={{
                    menu: true,
                    className: 'link material-icons',
                    text: 'more_vert',
                  }}
                  dropdown={{
                    clickCloses: true,
                    content: [
                      {
                        text: (!!data.transcode_job_id ? 'Retrigger' : 'Trigger') + ' Transcode Job',
                        icon: 'play_circle_outline',
                        onClick: () => triggerTranscodeJob(data.id),
                        show: true
                      },
                      {
                        text: 'Get Job Details',
                        icon: 'description',
                        onClick: () => toggleTranscodeJobModal(data),
                        show: !!data.transcode_job_id
                      }
                    ].filter(opt => opt.show)
                  }}/>
              ]
            },
            empty: {
              icon: <img src={Datapoints} alt='No Manifests'/>,
              title: 'No manifests!',
              text: 'Upload a manifest to get started.'
            }
          }}
          paginate={{
            currentPage: page,
            pageCount: Math.ceil(total / take),
            onPageChange: page => setPage(page)
          }}/>
      </Status>
      {showTranscodeJobModal && <TranscodeJobModal />}
    </div>
  )
}

const mapStateToProps = state => ({
  list: state.outputs.list,
  status: state.outputs.status,
  total: state.outputs.total,
  search: state.outputs.search,
  showTranscodeJobModal: state.transcode_jobs.modal.show
})

const mapDispatchToProps = dispatch => ({
  getOutputs: bindActionCreators(getOutputs, dispatch),
  setSearch: bindActionCreators(setSearch, dispatch),
  toggleTranscodeJobModal: bindActionCreators(toggleTranscodeJobModal, dispatch),
  triggerTranscodeJob: bindActionCreators(triggerTranscodeJob, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Outputs)