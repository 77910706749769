import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Checkbox } from '@bitcine/cs-theme'
import Select from 'react-select'
import { getSubtitleFiles } from '../../api/files'
import { confirmBurnedInLanguages, setSelectedSubtitles } from '../../api/matching'

const SubtitlesTable = ({ subtitles, subtitleFiles, getSubtitleFiles, selectedSubtitles, setSelectedSubtitles, burnedInLanguages, confirmBurnedInLanguages }) => {
  useEffect(() => {
    getSubtitleFiles()
  }, [getSubtitleFiles])
  return (
    <Table 
      widths={[200, 'auto']}
      header={{
        columns: [
          {},
          {}
        ]
      }}
      body={{
        data: subtitles,
        row : {
          render: [
            data => data,
            data => data.includes('Dyn') || data.includes('Syn')
              ? 
                <>
                  <Select 
                    className='col-12 mx1' 
                    options={subtitleFiles.map(file => ({ value: file.id, label: file.name }))}
                    //value={selectedSubtitles[data] ? selectedSubtitles[data].file_id : null}
                    onChange={({ value }) => setSelectedSubtitles({
                      ...selectedSubtitles,
                      [data]: {
                        file_id: value,
                        is_closed_captions: false
                      }
                    })}/>
                  <Checkbox 
                    label='Closed Captions?'
                    checked={selectedSubtitles[data] ? selectedSubtitles[data].is_closed_captions : false} 
                    disabled={!selectedSubtitles[data]}
                    onChange={() => setSelectedSubtitles({ 
                      ...selectedSubtitles,
                      [data]: {
                        ...selectedSubtitles[data],
                        is_closed_captions: !selectedSubtitles[data].is_closed_captions
                      }
                     })}/>
                </>
              :
                <Checkbox 
                  label='Burned-In Subtitles' 
                  checked={burnedInLanguages[data] || false} 
                  onChange={() => confirmBurnedInLanguages({ ...burnedInLanguages, [data]: !burnedInLanguages[data] })}/> 
          ]
        }
      }}/>
  )
}

const mapStateToProps = ({ files }) => ({
  subtitleFiles: files.subtitleFiles,
  selectedSubtitles: files.selectedSubtitles,
  burnedInLanguages: files.burnedInLanguages
})

const mapDispatchToProps = dispatch => ({
  getSubtitleFiles: bindActionCreators(getSubtitleFiles, dispatch),
  setSelectedSubtitles: bindActionCreators(setSelectedSubtitles, dispatch),
  confirmBurnedInLanguages: bindActionCreators(confirmBurnedInLanguages, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SubtitlesTable)