import React, { useEffect } from 'react'
import { connect } from 'react-redux' 
import { bindActionCreators } from 'redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ToastContainer, Slide, toast } from 'react-toastify'
import styled from '@emotion/styled'
import Login from './pages/Login'
import Inputs from './pages/Inputs'
import Outputs from './pages/Outputs'
import Dashboard from './pages/Dashboard'
import Files from './pages/Files'
import Matching from './pages/Matching'
import Uploads from './pages/Uploads'
import Downloads from './pages/Downloads'
import Distributors from './pages/Distributors'
import Distributor from './pages/Distributor'
import Account from './pages/Account'
import Invitation from './pages/Invitation'
import Spinner from './components/Spinner'
import HeaderBar from './components/HeaderBar'
import SideBar from './components/SideBar'
import Team from './components/Team'
import PoweredBy from './components/PoweredBy'
import { getUser } from './api/user'
import '@bitcine/cs-theme/dist/index.scss'
import 'react-toastify/dist/ReactToastify.css'

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  padding-bottom: 65px;
  margin-left: 220px;
  padding-top: 60px;
`

const App = ({ getUser, user }) => {
  useEffect(() => {
    getUser()
  }, [getUser])
  return (
    <>
      {user.status === 'PENDING' ? <Spinner/>
        : user.isAuthed ? (
        <PageContainer>
          <HeaderBar />
          <SideBar />
          <ContentContainer>
            <div className='p2'>
              <Switch>
                <Redirect exact path='/' to='/dashboard'/>
                <Route path="/dashboard" component={Dashboard} />
                <Route path='/account' component={Account} />
                <Route path='/team' component={Team} />
                <Route path='/files' component={Files} />
                <Route path='/inputs' component={Inputs} />
                <Route path='/outputs' component={Outputs} />
                <Route path='/matching' component={Matching} />
                <Route path='/uploads' component={Uploads} />
                <Route path='/downloads' component={Downloads} />
                <Route path='/distributors/:id' component={Distributor} />
                <Route path='/distributors' component={Distributors} />
                <Route path='/invitation/:token' component={Invitation} />
              </Switch>
            </div>
          </ContentContainer>
          <PoweredBy />
        </PageContainer>
      ) : (
        <>
          <Switch>
            <Route path='/invitation/:token' component={Invitation} />
            <Route path="*" component={Login} />
          </Switch>
        </>
      )}
      <ToastContainer transition={Slide} position={toast.POSITION.BOTTOM_RIGHT}/>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getUser: bindActionCreators(getUser, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
