export const GET_FILES = {
  REQUEST: "GET_FILES",
  PENDING: "GET_FILES_PENDING",
  FULFILLED: "GET_FILES_FULFILLED",
  FAILED: "GET_FILES_FAILED"
}

export const GET_SUBTITLE_FILES = {
  REQUEST: "GET_SUBTITLE_FILES",
  PENDING: "GET_SUBTITLE_FILES_PENDING",
  FULFILLED: "GET_SUBTITLE_FILES_FULFILLED",
  FAILED: "GET_SUBTITLE_FILES_FAILED"
}

export const MATCH_FILE = { 
  REQUEST: "MATCH_FILE",
  PENDING: "MATCH_FILE_PENDING",
  FULFILLED: "MATCH_FILE_FULFILLED",
  FAILED: "MATCH_FILE_FAILED"
}

export const SET_CHECKED_FILE = 'SET_CHECKED_FILE'
export const SET_SELECTED_SUBTITLES = 'SET_SELECTED_SUBTITLES'
export const SET_BURNED_IN_LANGUAGES = 'SET_BURNED_IN_LANGUAGES'

export const SET_SEARCH = 'SET_FILES_SEARCH'