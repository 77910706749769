export const GET_OUTPUTS = {
  REQUEST: "GET_OUTPUTS",
  PENDING: "GET_OUTPUTS_PENDING",
  FULFILLED: "GET_OUTPUTS_FULFILLED",
  FAILED: "GET_OUTPUTS_FAILED"
}

export const UPDATE_OUTPUT = {
  REQUEST: "UPDATE_OUTPUT",
  PENDING: "UPDATE_OUTPUT_PENDING",
  FULFILLED: "UPDATE_OUTPUT_FULFILLED",
  FAILED: "UPDATE_OUTPUT_FAILED"
}

export const SET_SEARCH = 'SET_OUTPUTS_SEARCH'