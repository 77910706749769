import {
  LOGIN,
  UPDATE_AUTH_FIELD,
  SET_JWT_TOKEN
} from './types'

const initialState = {
  isSubmitting: false,
  hasFailed: false,
  emailAttempt: '',
  passwordAttempt: '',
  jwtToken: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN.PENDING: 
      return {
        ...state,
        isSubmitting: true
      }
    case LOGIN.REJECTED: 
      return {
        ...state,
        isSubmitting: false,
        hasFailed: true
      }
    case LOGIN.FULFILLED: 
      return {
        ...state,
        isSubmitting: false,
        hasFailed: false
      }
    case UPDATE_AUTH_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    case SET_JWT_TOKEN:
      return {
        ...state,
        jwtToken: action.payload
      }
  default:
    return state
  }
}