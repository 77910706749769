import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Status, Table } from '@bitcine/cs-theme'
import { getDistributor, updateInvite, removeUser } from '../../api/distributors'
import InviteModal from './components/invite_modal'
import { ButtonDropdown } from '@bitcine/cs-theme'

class Distributor extends React.Component {
  componentDidMount() {
    this.props.getDistributor(this.props.match.params.id)
  }
  render() {
    const { distributor, status, updateInvite, invite, removeUser } = this.props
    return (
      <div>
        <Status
          pending={status === 'PENDING'}
          error={status === 'FAILED'}>
            <>
              <div className='flex items-center justify-between mb1'>
                <h3>{distributor.name}</h3>
                <div className='flex items-center'>
                  <button className='cs-button' onClick={() => updateInvite('showModal', true)}>Add User</button>
                </div>
              </div>
              <div>
                <Table 
                  widths={[260, 'auto',60]}
                  header={{
                    columns: [
                      { text: 'Name' },
                      { text: 'Email' },
                      { text: '' },
                    ]
                  }}
                  body={{
                    data: distributor.users,
                    row : {
                      compact: true,
                      render: [
                        data => data.name,
                        data => data.email,
                        data => (
                          <ButtonDropdown
                            button={{
                              className: 'cs-button link',
                              text: (
                                <span className='material-icons'>more_vert</span>
                              )
                            }}
                            dropdown={{
                              clickCloses: true,
                              content: [
                                {
                                  text: 'Remove ' + data.name,
                                  icon: 'delete',
                                  onClick: () => {
                                    removeUser(this.props.match.params.id, data.id)
                                  },
                                },
                              ]
                            }}
                          />
                        )
                      ]
                    },
                    empty: {
                      icon: 'people',
                      title: 'No users!',
                      text: 'Invite users with the button above.'
                    }
                  }} />
              </div>
            </>
        </Status>

        {invite.showModal && <InviteModal id={this.props.match.params.id} onClose={() => updateInvite('showModal', false)}/>}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getDistributor: bindActionCreators(getDistributor, dispatch),
  updateInvite: bindActionCreators(updateInvite, dispatch),
  removeUser: bindActionCreators(removeUser, dispatch)
})

const mapStateToProps = state => ({
  status: state.distributor.status,
  distributor: state.distributor.details,
  invite: state.distributor.invite
})

export default connect(mapStateToProps, mapDispatchToProps)(Distributor)