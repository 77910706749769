import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled'
import HeaderBar from '../HeaderBar'
import { Card } from '../AuthForm'
import BacksplashLowRes1 from '../../img/backsplash1_lowres.jpg'
import BacksplashLowRes2 from '../../img/backsplash2_lowres.jpg'
import BacksplashLowRes3 from '../../img/backsplash3_lowres.jpg'
import BacksplashLowRes4 from '../../img/backsplash4_lowres.jpg'
import BacksplashLowRes5 from '../../img/backsplash5_lowres.jpg'
import Backsplash1 from '../../img/backsplash1.jpg'
import Backsplash2 from '../../img/backsplash2.jpg'
import Backsplash3 from '../../img/backsplash3.jpg'
import Backsplash4 from '../../img/backsplash4.jpg'
import Backsplash5 from '../../img/backsplash5.jpg'

const lowRes = [BacksplashLowRes1, BacksplashLowRes2, BacksplashLowRes3, BacksplashLowRes4, BacksplashLowRes5]
const highRes = [Backsplash1, Backsplash2, Backsplash3, Backsplash4, Backsplash5]
const index = Math.floor(Math.random() * 5)
const lowResUrl = lowRes[index]
const highResUrl = highRes[index]

const Container = styled.div`
  height: 100%;
  top: 0;
  background: linear-gradient(rgba(255,255,255,.2), rgba(255,255,255,.2)), url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  display: table;
  position: absolute;
  width: 100%;
  z-index: -1;
`

const InnerForm = styled.div`
  display: table-cell;
  vertical-align: middle;
`

const AuthLayout = ({ children, header, description }) => {
  const [url, setUrl] = useState(lowResUrl)
  useEffect(() => {
    let highRes = new Image()
    highRes.src = highResUrl
    highRes.onload = () => {
      setUrl(highResUrl)
    }
  }, [])
  return (
    <div>
      <HeaderBar />
      <Container url={url}>
        <InnerForm>
          <Card>
            <h3>{header}</h3>
            <p>{description}</p>
            {children}
          </Card>
        </InnerForm>
      </Container>
    </div>
  )
}

export default AuthLayout