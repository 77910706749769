import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Status, Modal } from '@bitcine/cs-theme'
import { updateCreate, createDistributor } from '../../../api/distributors'

class CreateModal extends React.Component {
  close = () => {
    this.props.onClose()
    this.props.updateCreate('email', '')
    this.props.updateCreate('name', '')
    this.props.updateCreate('generatedPassword', '')
    this.props.updateCreate('organizationName', '')
    this.props.updateCreate('isSubmitting', false)
    this.props.updateCreate('success', false)
    this.props.updateCreate('error', '')
  }
  render() {
    const { create, updateCreate, createDistributor } = this.props
    return (
      <Modal
        open
        width={1}
        title={`Create a distributor`}
        onClose={this.close}>
        <Status pending={create.isSubmitting} error={false}>
          <label className='cs-label' htmlFor='dname'>Distributor's Name</label>
          <input 
            value={create.organizationName}
            onChange={e => updateCreate('organizationName', e.target.value)}
            type='text' 
            id='dname' 
            className='cs-input col-12 mb2' 
            placeholder='Enter the distributors name...' />
          <label className='cs-label' htmlFor='uname'>User's Name</label>
          <input 
            value={create.name}
            onChange={e => updateCreate('name', e.target.value)}
            type='text' 
            id='uname' 
            className='cs-input col-12 mb2' 
            placeholder='Enter the users name...' />
          <label className='cs-label' htmlFor='uemail'>User's Email</label>
          <input 
            value={create.email}
            onChange={e => updateCreate('email', e.target.value)}
            type='text' 
            id='uemail' 
            className='cs-input col-12 mb2' 
            placeholder='Enter the users email...' />
          <div className='flex justify-between pt3'>
            <button className='cs-button white'>Cancel</button>
            <button className='cs-button' onClick={createDistributor}>Submit</button>
          </div>
          {create.error.length > 0 &&
            <div className='center red pt2'>
              {create.error}
            </div>
          }
        </Status>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateCreate: bindActionCreators(updateCreate, dispatch),
  createDistributor: bindActionCreators(createDistributor, dispatch)
})

const mapStateToProps = state => ({
  create: state.distributors.create
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal)