import {
  GET_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR_INVITE,
  INVITE_DISTRIBUTOR_USER,
  REMOVE_DISTRIBUTOR_USER,
} from './types'

import {
  CREATE_DISTRIBUTOR
} from '../distributors/types'

const initialState = {
  status: 'PENDING',
  details: {},
  invite: {
    isSubmitting: false,
    success: false,
    showModal: false,
    name: '',
    email: '',
    generatedPassword: '',
    error: ''
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DISTRIBUTOR.PENDING:
    case REMOVE_DISTRIBUTOR_USER.PENDING:
      return {
        ...state,
        status: 'PENDING',
        details: {}
      }
    case GET_DISTRIBUTOR.FULFILLED:
    case REMOVE_DISTRIBUTOR_USER.FULFILLED:
      return {
        ...state,
        status: 'COMPLETE',
        details: action.payload.data.distributor
      }
    case GET_DISTRIBUTOR.FAILED:
      return {
        ...state,
        status: 'FAILED',
        details: {}
      }
    case UPDATE_DISTRIBUTOR_INVITE:
      return {
        ...state,
        invite: {
          ...state.invite,
          [action.payload.key]: action.payload.value
        }
      }
    case INVITE_DISTRIBUTOR_USER.PENDING:
      return {
        ...state,
        invite: {
          ...state.invite,
          success: false,
          isSubmitting: true,
          error: ''
        }
      }
    case INVITE_DISTRIBUTOR_USER.FULFILLED:
      return {
        ...state,
        details: action.payload.data.distributor,
        invite: {
          ...state.invite,
          success: true,
          isSubmitting: false,
          generatedPassword: action.payload.data.password,
          error: ''
        }
      }
    case INVITE_DISTRIBUTOR_USER.FAILED:
      return {
        ...state,
        invite: {
          ...state.invite,
          success: false,
          isSubmitting: false,
          error: action.payload.message
        }
      }
    case CREATE_DISTRIBUTOR.FULFILLED:
      return {
        ...state,
        invite: {
          ...state.invite,
          showModal: true,
          success: true,
          generatedPassword: action.payload.data.password,
          name: action.payload.data.user.name,
          email: action.payload.data.user.email
        }
      }
  default:
    return state
  }
}