import {
  UPDATE_PROFILE
} from '../../redux/profile/types.js'

import { put } from '../fetch'
import { toast } from "react-toastify"

export const updateProfile = (params) => dispatch => dispatch(
  put(UPDATE_PROFILE, 'me', params, res => {
    if(res.success) {
      toast.info("Profile updated")
    }
  })
)

