import React from 'react' // eslint-disable-line no-unused-vars
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const OuterContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%
`

const InnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const spin = keyframes`
  0% {
    transform: rotate(0deg)
  }
  30% {
    transform: rotate(200deg);
  }
  100% {
    transform: rotate(360deg)
  }
`

const InnerSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  :after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    border: 4px solid #fb0f3b;
    border-color: #fb0f3b #fb0f3b #fb0f3b #353149;
    animation: ${spin} 1.2s linear infinite;
  }
`

const Spinner = () => 
  <OuterContainer>
    <InnerContainer>
      <InnerSpinner/>
    </InnerContainer>
  </OuterContainer>

export default Spinner