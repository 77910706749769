/* global AW4 */
import uuidv4 from "uuid/v4"
import { get } from '../fetch'
import { toast } from "react-toastify"

export const triggerDownload = distributorUpload => dispatch => {
  dispatch(
    get(
      'GET_ASPERA_SETUP',
      `airline/uploads/aspera-setup/${distributorUpload.id}`,
      res => {
        dispatch(handleAsperaSetup(distributorUpload, res.data.asperaSetup))
      }
    )
  )
}

const handleAsperaSetup = (distributorUpload, asperaSetup) => dispatch => {
  const uuid = uuidv4()
  const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"
  const asperaWeb = new AW4.Connect({
    sdkLocation,
    minVersion: "3.6.0",
    id: uuid,
    dragDropEnabled: false
  })
  const asperaInstaller = new AW4.ConnectInstaller({ sdkLocation })

  asperaWeb.initSession(`nodeConnect-${uuid}`)

  asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (eventType, status) =>
    dispatch(asperaWebEventListener(eventType, status, asperaInstaller))
  )

  const opts = {
    title: `Select Save Location`,
    suggestedName: distributorUpload.filename
  }
  const callBacks = {
    success: path => {
      if (!path.dataTransfer.files.length) asperaWeb.stop()
      else {
        const transferSpec = {
          ...asperaSetup.transfer_specs[0].transfer_spec,
          destination_root: path.dataTransfer.files[0].name,
          authentication: "token"
        }

        const connectSettings = {
          allow_dialogs: "no",
          use_absolute_destination_path: true
        }

        asperaWeb.startTransfer(transferSpec, connectSettings)
      }
      toast.info("Download starting!")
    },
    error: err => {
      toast.error("An error occurred triggering the download.")
    }
  }

  asperaWeb.showSaveFileDialog(callBacks, opts)

  if (!window.asperaWeb) window.asperaWeb = asperaWeb
}

export const asperaWebEventListener = (eventType, dataStatus, asperaInstaller) => (dispatch, getState) => {
  const status = AW4.Connect.STATUS
  if (eventType === AW4.Connect.EVENT.STATUS) {
    switch (dataStatus) {
      case status.INITIALIZING:
        asperaInstaller.showLaunching()
        break
      case status.FAILED:
        asperaInstaller.showDownload()
        break
      case status.OUTDATED:
        asperaInstaller.showUpdate()
        break
      case status.RUNNING:
        asperaInstaller.connected()
        break
      case status.RETRYING:
        break
      default:
        break
    }
  }
}