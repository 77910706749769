/** @jsx jsx */
import React from 'react'
import { jsx, keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const shine = keyframes`
  0% { transform:translateX(-100%); }
  100% { transform:translateX(100%); }
`

const Card = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 2px;
  padding: 24px;
  z-index:0;
  position: relative;
  overflow: hidden;
  opacity: 0.75;
  &:after {
    content:'';
    top:0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:1;
    animation: ${shine} 2s infinite;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 );
  }
`

const Icon = styled.div`
  height: 86px;
  width: 86px;
  border-radius: 43px;
  background-color: #D8D8D8;
  color: #545C63;
  line-height: 86px;
  text-align:center;
  font-size: 32px;
  font-weight: bold;
  margin: 0 auto 20px auto;
`

const Name = styled.div`
  height: 24px;
  border-radius: 5px;
  background-color: #D8D8D8;
  margin-bottom: 10px;
`
const Email = styled.div`
  height: 20px;
  border-radius: 5px;
  background-color: #D8D8D8;
`
const Wrap = styled.div`
  position: relative;
`

const Grad = styled.div`
  z-index: 2;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  background: rgb(249,249,249);
  background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(249,249,249,0) 100%);
`

class Skeleton extends React.Component {
  render() {
    return (
      <Wrap className='clearfix mxn2'>
        <Grad />
        {[...Array(8)].map((a, i) => 
          <div key={i} className='col col-3 p2'>
            <Card>
              <Icon />
              <Name />
              <Email />
            </Card>
          </div>
        )}
      </Wrap>
    )
  }
}

export default Skeleton