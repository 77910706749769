import {
  TOGGLE_WORKSPACE_MODAL,
  UPDATE_WORKSPACE_FORM,
  CREATE_WORKSPACE,
  GET_WORKSPACES,
  SET_WORKSPACE
} from './types'

const initialState = {
  status: 'PENDING',
  list: [],
  modals: {
    create: false,
    list: false
  },
  form: {
    name: '',
    isSubmitting: false,
    error: ''
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case TOGGLE_WORKSPACE_MODAL:
    return {
      ...state,
      modals: {
        ...state.modals,
        [action.payload.type]: !state.modals[action.payload.type]
      }
    }
  case UPDATE_WORKSPACE_FORM:
    return {
      ...state,
      form: {
        ...state.form,
        [action.payload.key]: action.payload.value 
      }
    }
  case GET_WORKSPACES.PENDING:
  case SET_WORKSPACE.PENDING:
    return {
      ...state,
      status: 'PENDING',
      list: []
    }
  case GET_WORKSPACES.FULFILLED:
    return {
      ...state,
      status: 'READY',
      list: action.payload.data.workspaces
    }
  case GET_WORKSPACES.FAILED:
    return {
      ...state,
      status: 'ERROR',
      list: []
    }
  case CREATE_WORKSPACE.PENDING:
    return {
      ...state,
      form: {
        isSubmitting: true,
        error: ''
      }
    }
  case CREATE_WORKSPACE.FULFILLED:
  case SET_WORKSPACE.FULFILLED:
    return {
      ...state,
      ...initialState
    }
  case CREATE_WORKSPACE.FAILED:
    return {
      ...state,
      form: {
        ...state.form,
        isSubmitting: false,
        error: action.payload.message
      }
    }
  default:
    return state
  }
}