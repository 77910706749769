import React from "react"
import PropTypes from "prop-types"
import styled from '@emotion/styled'

const Initials = styled.span`
  display: inline-block;
  margin-right: 1em;
  margin-left: 1em;
  color: white;
  padding-top: 0.4em;
  font-size: medium;
  min-width: 30px;
  height: 30px;
  background-color: gray;
  border-radius: 15px;
  text-align: center;
`

const MemberIcon = ({ name }) => {
  return (
    <Initials>{name ? getInitials(name) : <span>&nbsp;</span>}</Initials>
  )
}

const getInitials = name => {
  const split = name.split(" ")
  return split[0].charAt(0) + (typeof split[1] === "string" ? split[1].charAt(0) : "")
}

MemberIcon.defaultProps = {
  zindex: 1
}

MemberIcon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default MemberIcon
