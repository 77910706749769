import {
  GET_USER
} from './types'

import {
  LOGIN,
  LOGOUT
} from '../auth/types'

import {
  UPDATE_INVITE_FULFILLED
} from '../invite/types'

import {
  UPDATE_PROFILE_FULFILLED,
} from '../profile/types'

import {
  CREATE_WORKSPACE,
  SET_WORKSPACE
} from '../workspace/types'

const initialState = {
  status: 'PENDING',
  isAuthed: false,
  details: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER.PENDING: 
      return {
        ...state,
        status: 'PENDING',
        isAuthed: false,
        details: {}
      }
    case GET_USER.FULFILLED:
    case UPDATE_INVITE_FULFILLED:
    case UPDATE_PROFILE_FULFILLED:
    case CREATE_WORKSPACE.FULFILLED:
    case SET_WORKSPACE.FULFILLED:
      return {
        ...state,
        status: 'READY',
        isAuthed: true,
        details: action.payload.data.user
      }
    case LOGIN.FULFILLED:
      return {
        ...state,
        status: 'READY',
        isAuthed: true,
        details: action.payload.data.user
      }
    case GET_USER.REJECTED:
    case LOGOUT:
      return {
        ...state,
        status: 'READY',
        isAuthed: false,
        details: {}
      }
  default:
    return state
  }
}