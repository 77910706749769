import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Status } from '@bitcine/cs-theme'
import { toggleWorkspaceModal, getWorkspaces, setActiveWorkspace } from '../../../api/workspace'
import dayjs from 'dayjs'

const DropdownContainer = styled.div`
  position: fixed;
  top: 65px;
  left: 240px;
  height: 500px;
  width: 400px;
  background: #fff;
  border: 1px solid #c7cdcf;
  border-radius: 5px;
  box-shadow: 0 0 9px 0 rgba(0,0,0,.13);
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 60px;
    top: -10px;
    background: #fff;
    height: 18px;
    width: 18px;
    border-top: 1px solid #c7cdcf;
    border-left: 1px solid #c7cdcf;
    transform: rotate(45deg);
    border-radius: 2px;
  }
`

const Search = styled.div`
  border-bottom: 1px solid #c7cdcf;
  border-top: 1px solid #c7cdcf;
`

const ScrollContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 187px;
  bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-y: scroll; 
`

const Item = styled.button`
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  &:hover {
    background: #F9F9F9;
  }
`

const Add = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 0.5em;
  padding: 0;
  margin: 0;
`

const Active = styled.div`
  border-bottom: 1px solid #c7cdcf;
  i {
    color: green;
    font-size: 1.25em;
  }
`

class WorkspaceDropdown extends React.Component {
  constructor() {
    super()
    this.state = {
      search: ''
    }
  }
  componentDidMount() {
    this.props.getWorkspaces()
    document.addEventListener('mousedown', this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  setWrapperRef = (node) => {
    this.wrapperRef = node
  }
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.toggleWorkspaceModal('list')
    }
  }
  render() {
    const { toggleWorkspaceModal, status, list, activeWorkspace, setActiveWorkspace } = this.props
    const filtered = list.filter(item => {
      return item.name.trim().toLowerCase().includes(this.state.search.trim().toLowerCase())
    })
    return (
      <DropdownContainer ref={this.setWrapperRef}>
        <div className='flex items-center justify-between p2'>
          <h4>Workspaces</h4>
          <Add disabled={status === 'PENDING'} onClick={() => {
            toggleWorkspaceModal('list')
            toggleWorkspaceModal('create')
          }}>
            <i className='material-icons'>post_add</i>
          </Add>
        </div>
        <Search className='p2'>
          <input 
            autoFocus
            disabled={status === 'PENDING'}
            value={this.state.search}
            onChange={e => this.setState({ search: e.target.value })}
            className='cs-input col-12' 
            placeholder="Search..." />
        </Search>
        <Active className='flex items-center justify-between p2'>
          <span className='truncate'>{activeWorkspace.name}</span>
          <i className='material-icons'>check_circle</i>
        </Active>
        <ScrollContainer>
          <Status 
            pending={status === 'PENDING'}
            error={status === 'ERROR'}>
            {filtered.map(workspace =>
              <Item className='flex items-center justify-between p2' key={workspace.id} onClick={() => {
                setActiveWorkspace(workspace.id)
              }}>
                <span className='truncate'>{workspace.name}</span>
                <small>{dayjs(workspace.created_at).format('MMM D, YYYY')}</small>
              </Item>
            )}
          </Status>
        </ScrollContainer>
      </DropdownContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  status: state.workspace.status,
  list: state.workspace.list.reverse().filter(item => item.id !== state.user.details.organization.workspace.id),
  activeWorkspace: state.user.details.organization.workspace
})

const mapDispatchToProps = (dispatch) => ({
  toggleWorkspaceModal: bindActionCreators(toggleWorkspaceModal, dispatch),
  getWorkspaces: bindActionCreators(getWorkspaces, dispatch),
  setActiveWorkspace: bindActionCreators(setActiveWorkspace, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceDropdown)