export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS'
export const GET_TEAM_MEMBERS_PENDING = 'GET_TEAM_MEMBERS_PENDING'
export const GET_TEAM_MEMBERS_FULFILLED = 'GET_TEAM_MEMBERS_FULFILLED'
export const GET_TEAM_MEMBERS_REJECTED = 'GET_TEAM_MEMBERS_REJECTED'

export const UPDATE_TEAM_INVITE = 'UPDATE_TEAM_INVITE'

export const INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER'
export const INVITE_TEAM_MEMBER_PENDING = 'INVITE_TEAM_MEMBER_PENDING'
export const INVITE_TEAM_MEMBER_FULFILLED = 'INVITE_TEAM_MEMBER_FULFILLED'
export const INVITE_TEAM_MEMBER_REJECTED = 'INVITE_TEAM_MEMBER_REJECTED'

export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER'
export const REMOVE_TEAM_MEMBER_PENDING = 'REMOVE_TEAM_MEMBER_PENDING'
export const REMOVE_TEAM_MEMBER_FULFILLED = 'REMOVE_TEAM_MEMBER_FULFILLED'
export const REMOVE_TEAM_MEMBER_REJECTED = 'REMOVE_TEAM_MEMBER_REJECTED'