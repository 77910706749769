import {
  UPDATE_PASSWORD,
  CHANGE_PASSWORD_FIELD
} from '../../redux/security/types'

import { post } from '../fetch'
import { toast } from "react-toastify"

export const updatePassword = () => (dispatch, getState) => dispatch(
  post(UPDATE_PASSWORD, 'me/password', { 
    current: getState().security.password.current,
    password: getState().security.password.new,
    password_confirmation: getState().security.password.confirmation
  }, res => {
    if(res.success) {
      toast.info("Password updated")
    }
  })
)

export const changePasswordField = (key, value) => dispatch => dispatch({
  type: CHANGE_PASSWORD_FIELD,
  payload: { key, value }
})