import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateProfile } from '../../api/profile'

class UpdateProfile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.user.name,
      email: props.user.email
    }
  }
  render() {
    const { name, email } = this.state
    const { profile, updateProfile } = this.props
    return (
      <>
        <h4 className='mb1'>Profile</h4>
        <label htmlFor='name' className='cs-label'>Name</label>
        <input 
          id='name'
          className='cs-input col-12 mb2'
          type='text'
          placeholder='Name'
          value={name}
          disabled={profile.isSubmitting}
          onChange={(e) => this.setState({ name: e.target.value })} />
        <label htmlFor='email' className='cs-label'>Email</label>
        <input 
          id='email'
          className='cs-input col-12 mb3'
          type='text'
          placeholder='Email'
          value={email}
          disabled={profile.isSubmitting}
          onChange={(e) => this.setState({ email: e.target.value })} />

        <button className='cs-button' disabled={profile.isSubmitting} onClick={() => {
          updateProfile({ name, email })
        }}>
          {profile.isSubmitting ? 'Saving...' : 'Save'}
        </button>

        {profile.error.length > 0 && (
          <div className='pt2 red'>
            {profile.error}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.details,
  profile: state.profile
})

const mapDispatchToProps = dispatch => ({
  updateProfile: bindActionCreators(updateProfile, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile)