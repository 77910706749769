import {
  GET_DISTRIBUTORS,
  UPDATE_CREATE_DISTRIBUTOR,
  CREATE_DISTRIBUTOR,
  SET_SEARCH
} from './types'

const initialState = {
  status: 'PENDING',
  list: [],
  total: 0,
  search: '',
  create: {
    isSubmitting: false,
    showModal: false,
    error: '',
    organizationName: '',
    name: '',
    email: '',
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      }
    case GET_DISTRIBUTORS.PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: []
      }
    case GET_DISTRIBUTORS.FULFILLED:
      return {
        ...state,
        status: state.search === action.payload.data.search ? 'COMPLETE' : state.status,
        list: state.search === action.payload.data.search ? action.payload.data.distributors : state.list,
        total: state.search === action.payload.data.search ? action.payload.data.total : state.total
      }
    case GET_DISTRIBUTORS.FAILED:
      return {
        ...state,
        status: 'FAILED',
        list: []
      }
    case UPDATE_CREATE_DISTRIBUTOR:
      return {
        ...state,
        create: {
          ...state.create,
          [action.payload.key]: action.payload.value
        }
      }
    case CREATE_DISTRIBUTOR.PENDING:
      return {
        ...state,
        create: {
          ...state.create,
          isSubmitting: true,
          error: ''
        }
      }
    case CREATE_DISTRIBUTOR.FULFILLED:
      return {
        ...state,
        create: {
          ...state.create,
          isSubmitting: false,
          showModal: false
        }
      }
    case CREATE_DISTRIBUTOR.FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isSubmitting: false,
          error: action.payload.message
        }
      }
  default:
    return state
  }
}